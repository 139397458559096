import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useStateDebounce } from "common/hooks";

export interface DemandListFilterSearchTextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void | Promise<void>;
}

export const DemandListFilterSearchTextField = ({ label, value, onChange }: DemandListFilterSearchTextFieldProps) => {
  const [searchValue, setSearchValue] = useState<string>(value);

  useStateDebounce(searchValue, onChange, 500);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <TextField
      sx={{ width: { sm: "100%", md: "30%" } }}
      variant="outlined"
      size="small"
      label={label}
      type="search"
      value={searchValue}
      onChange={onInputChange}
    />
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { useGetDemandListConsumerQuery, useGetDemandListConveyorQuery } from "demand/list/api/demandListApi";
import { useAppSelector } from "common/store";
import { selectDemandList, selectDemandListIds } from "demand/list/store/demandList.selectors";
import { AppInfinityScroll, useHasPreviousPage } from "common/ui/lists/infinite-scroll";
import { AppInfinityScrollTitles } from "common/ui/lists/infinite-scroll/components/AppInfinityScroll";
import { DemandListFilterParams } from "demand/list/types/DemandListFilterParams";
import { DemandListQueryMutator } from "demand/list/hooks/useDemandListFilterMutator";
import { Stack } from "@mui/material";
import { DemandListItem } from "demand/list/components/DemandListItem";
import { DemandId } from "common/types";
import { useCreateNavigationStateFrom } from "common/navigation/navigation-state";

const titles: AppInfinityScrollTitles = {
  end: "Больше нет заявок",
  hasNoLoadedItems: "Заявок нет",
  navigateToFirstPage: "Показать предыдущие заявки",
  error: "При получении заявок возникла ошибка",
};

export interface DemandListItemsContainerProps {
  filteringObject: DemandListFilterParams;
  mutator: DemandListQueryMutator;
  fetcher: typeof useGetDemandListConveyorQuery | typeof useGetDemandListConsumerQuery;
}

export const DemandListItemsContainer = ({ filteringObject, mutator, fetcher }: DemandListItemsContainerProps) => {
  const from = useCreateNavigationStateFrom("Мои заявки");
  const hasPreviousPage = useHasPreviousPage(filteringObject);
  const [isNextPageFetching, setIsNextPageFetching] = useState(false);

  const { isError, isLoading, isSuccess, refetch } = fetcher(filteringObject, {
    refetchOnMountOrArgChange: true,
  });

  const items = useAppSelector(selectDemandListIds);

  useEffect(() => {
    if (isNextPageFetching) {
      mutator(filteringObject, "pageNumber", filteringObject.pageNumber + 1);
    }

    setIsNextPageFetching(false);
  }, [filteringObject, mutator, isNextPageFetching]);

  const onNextPageFetching = useCallback(() => {
    setIsNextPageFetching(true);
  }, []);

  const onPreviousPageButtonClick = () => {
    mutator(filteringObject, "pageNumber", 1);
  };

  return (
    <div>
      <AppInfinityScroll
        page={filteringObject.pageNumber}
        paginatedStateSelector={(x) => selectDemandList(x).pagination}
        idsSelector={selectDemandListIds}
        titles={titles}
        onPreviousPageButtonClick={onPreviousPageButtonClick}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={refetch}
        hasPreviousPage={hasPreviousPage}
        isFailed={isError}
        isLoading={isLoading}
        isSucceeded={isSuccess}
      >
        <Stack spacing={2}>
          {items.map((x) => (
            <DemandListItem key={x} id={x as DemandId} from={from} />
          ))}
        </Stack>
      </AppInfinityScroll>
    </div>
  );
};

import React from "react";
import { Alert, AlertTitle } from "@mui/material";

export interface ErrorAlertProps {
  title?: string;
  error: React.ReactNode;
}

export const ErrorAlert = ({ title, error }: ErrorAlertProps) => {
  return (
    <Alert severity="error">
      {title && <AlertTitle>{title}</AlertTitle>}
      {error}
    </Alert>
  );
};

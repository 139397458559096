import React from "react";
import { Alert, AlertTitle, Box, Divider, Stack, Typography } from "@mui/material";
import { QueryError } from "common/api/types/QueryError";
import { QueueTasksDetailsSendDecisionButton } from "queue-tasks/details/components/QueueTasksDetailsSendDecisionButton";
import { QueueTasksDetailsResultTypes } from "queue-tasks/details/types/QueueTasksDetailsResultTypes";

export interface QueueTasksDetailsDecisionsGridProps {
  title: string;
  success: boolean;
  successMessage: string;
  error: boolean;
  queryError: QueryError | undefined;
  errorFallbackMessage: string;
  errorTitle: string;
  loading: boolean;
  sentDecision?: string;
  resultTypes: QueueTasksDetailsResultTypes;
  onSendDecisionClick: (decision: string) => void | Promise<void>;
}

export const QueueTasksDetailsDecisionsGrid = ({
  resultTypes,
  success,
  title,
  successMessage,
  error,
  queryError,
  errorFallbackMessage,
  sentDecision,
  loading,
  errorTitle,
  onSendDecisionClick,
}: QueueTasksDetailsDecisionsGridProps) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={0.5}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Divider />
      </Stack>
      {success && <Alert severity="success">{successMessage}</Alert>}
      {error && (
        <Alert severity="error">
          <AlertTitle>{errorTitle}</AlertTitle>
          {queryError?.data?.message ?? errorFallbackMessage}
        </Alert>
      )}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        {!success &&
          resultTypes.values.map((x) => (
            <Box key={x} gridColumn={{ xs: "span 12", md: "span 4" }}>
              <QueueTasksDetailsSendDecisionButton
                decision={x}
                title={resultTypes.valuesWithTitles[x]}
                onClick={onSendDecisionClick}
                disabled={loading}
                loading={loading && sentDecision === x}
              />
            </Box>
          ))}
      </Box>
    </Stack>
  );
};

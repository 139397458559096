import React, { useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Card, CardActions, CardContent, Divider, Stack, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useUploadFilesConsumerMutation } from "files/upload";
import { useAppDispatch, useAppSelector } from "common/store";
import { productLeasingCreateFileClear } from "product-leasing/create/store/productLeasingCreateFiles.slice";
import { selectProductLeasingCreateFileIds } from "product-leasing/create/store/productLeasingCreateFiles.selectors";
import { ProductLeasingCreationUploadFile } from "product-leasing/create/components/ProductLeasingCreationUploadFile";
import { getDropzonePallette } from "common/ui/theme";

export interface ProductLeasingCreateUploadDocumentCardProps {
  disabled: boolean;
}

export const ProductLeasingCreateUploadDocumentCard = ({ disabled }: ProductLeasingCreateUploadDocumentCardProps) => {
  const dispatch = useAppDispatch();
  const ids = useAppSelector(selectProductLeasingCreateFileIds);
  const [upload] = useUploadFilesConsumerMutation();
  const { acceptedFiles, getRootProps, getInputProps, open, isDragAccept, isDragReject, isDragActive } = useDropzone({
    disabled,
    noClick: true,
  });

  useEffect(() => {
    dispatch(productLeasingCreateFileClear());

    return () => {
      dispatch(productLeasingCreateFileClear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (acceptedFiles.length) {
      upload({ files: acceptedFiles });
    }
  }, [acceptedFiles, upload]);

  return (
    <Card
      sx={(theme) => ({
        borderWidth: isDragActive ? "1px" : "0px",
        borderStyle: "solid",
        borderColor: getDropzonePallette(theme, { isDragActive, isDragReject, isDragAccept })?.light,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          Документы по заявке
        </Typography>
        <Stack spacing={1} direction="column" mt={2} alignItems="start">
          {ids.map((x) => (
            <ProductLeasingCreationUploadFile id={x as string} key={x} />
          ))}
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Button size="small" startIcon={<CloudUploadIcon />} onClick={open} disabled={disabled}>
          Прикрепить файлы
        </Button>
      </CardActions>
    </Card>
  );
};

import React from "react";
import { UserId } from "common/types";
import { usePostUserRegistrationAddPasswordMutation } from "user/registration/api/userRegistrationApi";
import { useFormik } from "formik";
import { Alert, Button, LinearProgress, Stack, TextField } from "@mui/material";
import { QueryError } from "common/api/types/QueryError";
import {
  userRegistrationAddPasswordFormValidationSchema,
  UserRegistrationAddPasswordFormValues,
} from "user/registration/types/UserRegistrationAddPasswordFormValues";
import { formFieldsApiValidationService } from "common/services/form";
import { userRegistrationAddUserFormValueKeys } from "user/registration/types/UserRegistrationAddUserFormValues";
import { UserAuthLoginResponse } from "user/auth/api/dto/response/UserAuthLoginResponse";
import { PasswordTextField } from "common/ui/inputs";
import { makeUserAuthDetails, UserAuthDetails } from "user/auth";

export interface UserRegistrationAddPasswordFormProps {
  userId: UserId;
  onSuccess: (userAuthDetails: UserAuthDetails) => void | Promise<void>;
}

export const UserRegistrationAddPasswordForm = ({ userId, onSuccess }: UserRegistrationAddPasswordFormProps) => {
  const [addPassword, options] = usePostUserRegistrationAddPasswordMutation();

  const form = useFormik<UserRegistrationAddPasswordFormValues>({
    initialValues: userRegistrationAddPasswordFormValidationSchema.getDefault(),
    validationSchema: userRegistrationAddPasswordFormValidationSchema,
    onSubmit: async ({ password }, helpers) => {
      const response = await addPassword({ password, userId });
      const { error } = response as { error: QueryError };

      if (error) {
        formFieldsApiValidationService.setFieldErrors(
          error.data,
          userRegistrationAddUserFormValueKeys,
          helpers.setFieldError
        );

        return;
      }

      const { data } = response as { data: UserAuthLoginResponse };
      if (data) {
        onSuccess(makeUserAuthDetails(data.login, data.name, data.roles));
      }
    },
  });

  return (
    <Stack component="form" onSubmit={form.handleSubmit} noValidate spacing={2} sx={{ width: "100%" }}>
      <PasswordTextField
        error={form.touched.password && Boolean(form.errors.password)}
        disabled={options.isLoading}
        required
        showPassword={form.values.showPassword}
        onShowPasswordClick={() => form.setFieldValue("showPassword", !form.values.showPassword)}
        value={form.values.password}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        helperText={form.touched.password ? form.errors.password : undefined}
        label="Придумайте пароль"
        name="password"
        autoComplete="new-password"
      />

      {!form.values.showPassword && (
        <TextField
          error={form.touched.repeatPassword && Boolean(form.errors.repeatPassword)}
          disabled={options.isLoading}
          required
          type="password"
          value={form.values.repeatPassword}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.touched.repeatPassword ? form.errors.repeatPassword : undefined}
          label="Повторите пароль"
          name="repeatPassword"
          autoComplete="new-password"
        />
      )}

      {options.isError && options.error && (
        <Alert severity="error">
          {(options.error as QueryError).data?.message ?? "Не удалось установить соединение с сервером"}
        </Alert>
      )}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        disabled={!form.isValid || !form.dirty || form.isSubmitting}
      >
        Установить пароль
      </Button>
      {form.isSubmitting && <LinearProgress color="inherit" />}
    </Stack>
  );
};

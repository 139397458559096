import { SimpleFile } from "files/common/types";
import { ProductLeasingCreateFile } from "product-leasing/create/types/ProductLeasingCreateFile";
import { nanoid } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { getDownloadFileLink } from "files/download";

export const productLeasingCreateFilesMapper = {
  fromLocal: (requestId: string, status: QueryStatus, file: File): ProductLeasingCreateFile => ({
    id: nanoid(),
    requestId,
    name: file.name,
    isUploaded: false,
    status,
  }),
  formExists: (file: SimpleFile): ProductLeasingCreateFile => ({
    id: file.fileId,
    isUploaded: true,
    name: file.fileName,
    downloadLink: getDownloadFileLink(file),
    status: QueryStatus.fulfilled,
  }),
};

import React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { BrandDarkLogo } from "common/ui/theme/BrandLogo";
import { Copyright } from "common/ui/layout/components/Copyright";

export interface PublicAuthUserLayoutProps {
  children: React.ReactNode;
}

export const PublicAuthUserLayout = ({ children }: PublicAuthUserLayoutProps) => {
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        minWidth: "100%",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          width: { xs: "100%", md: "400px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <BrandDarkLogo />
        </Box>
        {children}
      </Box>
      <Copyright sx={{ m: 4, flexGrow: 0 }} />
    </Container>
  );
};

import React from "react";
import { useParams } from "react-router-dom";
import { DemandId } from "common/types";
import { ErrorAlert } from "common/ui/alerts";
import { DemandConsumerDetails } from "demand/consumer-details/components/DemandConsumerDetails";

export const DemandConsumerDetailsPage = () => {
  const { demandId } = useParams<{ demandId: DemandId }>();

  if (!demandId) {
    return <ErrorAlert error="Заявка не найдена" />;
  }

  return <DemandConsumerDetails demandId={demandId} />;
};

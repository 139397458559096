import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiConfig } from "common/api/config";
import { ApiResponse } from "common/api/types/ApiResponse";
import { BaseQueryApi } from "@reduxjs/toolkit/src/query/baseQueryTypes";
import { ApiErrors } from "common/api/types/ApiErrors";
import { QueryErrorReturnValue } from "common/api/types/QueryErrorReturnValue";

const fetch = fetchBaseQuery({
  baseUrl: apiConfig.basePath,
  credentials: "include",
  cache: "no-cache",
  mode: "cors",
});

export type BaseQueryFn<Args = string | FetchArgs, Result = unknown, DefinitionExtraOptions = Record<never, string>> = (
  args: Args,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions
) => Promise<QueryReturnValue<Result>>;

export type QueryReturnValue<TData> = { data: TData } | QueryErrorReturnValue;

const wrappedFetch = (): BaseQueryFn => {
  return async (args, api, extraOptions): Promise<QueryReturnValue<unknown>> => {
    const result = await fetch(args, api, extraOptions);

    if (result.data) {
      const { body, error } = result.data as ApiResponse<typeof result.data>;
      if (error === false && body !== undefined) {
        return { data: body };
      }
    }

    if (result.error) {
      const apiResponse = result.error?.data as ApiResponse<typeof result.error.data>;
      if (apiResponse?.error) {
        return { error: { status: result.error.status as number, data: apiResponse.errors ?? ({} as ApiErrors) } };
      }

      if (result.error?.status === 401) {
        return { error: { status: 401, data: {} as ApiErrors } };
      }
    }

    throw Error("Не удалось отправить запрос");
  };
};

export const appApi = createApi({
  baseQuery: wrappedFetch(),
  endpoints: () => ({}),
});

import { ApiErrors } from "common/api/types/ApiErrors";

const setFieldErrors = (
  apiError: ApiErrors | undefined,
  formFieldNames: string[],
  setFieldError: (field: string, message: string | undefined) => void
) => {
  if (!apiError?.fields) {
    return;
  }
  Object.entries(apiError.fields).forEach(([field, errors]) => {
    const formattedFieldName = field
      .split(".")
      .map((x) => (x.length > 0 ? x[0].toLowerCase() + x.slice(1) : x))
      .join(".");

    if (formFieldNames.includes(formattedFieldName)) {
      setFieldError(formattedFieldName, errors.join("; "));
    }
  });
};

export const formFieldsApiValidationService = {
  setFieldErrors,
};

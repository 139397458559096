import { appApi, EmptyResponse } from "common/api";
import { UserAuthLoginResponse } from "user/auth/api/dto/response/UserAuthLoginResponse";
import { UserAuthLoginRequest } from "user/auth/api/dto/request/UserAuthLoginRequest";

const CONTROLLER_NAME = "/api/v1/Anonymous/accounts/auth";

export const userAuthApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    postUserAuthLogin: build.mutation<UserAuthLoginResponse, UserAuthLoginRequest>({
      query: (queryArg) => ({
        url: `${CONTROLLER_NAME}/Authorize`,
        method: "POST",
        body: queryArg,
      }),
    }),
    putUserAuthLogout: build.mutation<EmptyResponse, unknown>({
      query: () => ({ url: `${CONTROLLER_NAME}/logout`, method: "PUT" }),
    }),
  }),
});

export const { usePostUserAuthLoginMutation, usePutUserAuthLogoutMutation } = userAuthApi;

import { InferType, object, string } from "yup";

export const userRegistrationVerificationCodeFormValidationSchema = object({
  code: string()
    .transform((value, originalValue) => {
      return originalValue.replace(/\D/g, "");
    })
    .default("")
    .required("Укажите код подтверждения")
    .matches(/^\d+$/, "Код должен содержать только цифры")
    .min(6, "Код должен состоять из 6 цифр")
    .max(6, "Код должен состоять из 6 цифр"),
});

export type UserRegistrationVerificationCodeFormValues = InferType<
  typeof userRegistrationVerificationCodeFormValidationSchema
>;

import { LoginFormValues } from "user/auth/types/LoginFormValues";
import { usePostUserAuthLoginMutation } from "user/auth/api/userAuthApi";
import { useCallback } from "react";
import { makeUserAuthDetails } from "user/auth/types/UserAuthDetails";
import { UserAuthLoginRequest } from "user/auth/api/dto/request/UserAuthLoginRequest";
import { QueryError } from "common/api/types/QueryError";
import { useSetUserAuth } from "user/auth/hooks/useSetUserAuth";

export const useUserAuthLogin = () => {
  const [loginUser, options] = usePostUserAuthLoginMutation();
  const setUserAuth = useSetUserAuth();

  const requestFunc = useCallback(
    async ({ phoneNumber, password }: LoginFormValues): Promise<QueryError | undefined> => {
      try {
        const request: UserAuthLoginRequest = {
          login: phoneNumber,
          password,
        };

        const { login, roles, name } = await loginUser(request).unwrap();
        setUserAuth(makeUserAuthDetails(login, name, roles));
      } catch (err) {
        return err as QueryError;
      }

      return undefined;
    },
    [setUserAuth, loginUser]
  );

  return [requestFunc, options] as const;
};

import React from "react";
import { DemandId } from "common/types";
import { LinearProgress, Stack } from "@mui/material";
import { QueryError } from "common/api/types/QueryError";
import { DemandDetailsView } from "demand/details";
import { RefetchErrorAlert } from "common/ui/alerts";
import { useGetDemandConveyorDetailsQuery } from "demand/conveyor-details/api/demandDetailsApi";
import Box from "@mui/material/Box";
import { BackStateLinkButton } from "common/ui/buttons";
import { selectAppUrl } from "common/urls";
import { DemandConveyorCommentWrapper } from "demand/comments";

export interface DemandConveyorDetailsProps {
  demandId: DemandId;
}

export const DemandConveyorDetails = ({ demandId }: DemandConveyorDetailsProps) => {
  const { data, isLoading, isError, refetch, error } = useGetDemandConveyorDetailsQuery({ demandId });

  if (isError) {
    return (
      <RefetchErrorAlert
        error={error as QueryError}
        errorFallback="Не удалось получить заявку"
        onRefetchClick={refetch}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <Box alignSelf="self-start">
        <BackStateLinkButton fallbackLink={selectAppUrl((x) => x.demand.index).url} fallbackTitle="Мои заявки" />
      </Box>
      {isLoading && <LinearProgress />}
      {data && (
        <>
          <DemandDetailsView details={data} showUser showQueueTaskLink />
          <DemandConveyorCommentWrapper demandId={demandId} />
        </>
      )}
    </Stack>
  );
};

import { AppUrlSelector, selectAppUrl } from "common/urls";
import React from "react";
import { AppLayoutRoute, AppRoute, AppRouteUserRoleElement } from "common/routes/types/AppRoute";

export const makeAppRoute = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selector: AppUrlSelector<any, any>,
  children: React.ReactNode,
  nested?: (AppRoute | AppLayoutRoute)[],
  permissionElement?: AppRouteUserRoleElement
): AppRoute => {
  const appUrl = selectAppUrl(selector);

  return {
    url: appUrl,
    element: children,
    nested,
    permissionElement,
  };
};

import React from "react";
import { AppLayoutRoute, AppRoute } from "common/routes/types/AppRoute";

export const makeAppLayoutRoute = (
  component: (props: { children: React.ReactNode }) => React.ReactElement,
  nested?: (AppRoute | AppLayoutRoute)[]
): AppLayoutRoute => {
  return {
    component,
    nested,
    isLayout: true,
  };
};

import { appApi } from "common/api";
import { CreateProductLeasingResponse } from "product-leasing/create/api/dto/response/CreateProductLeasingResponse";
import { CreateProductLeasingRequest } from "product-leasing/create/api/dto/request/CreateProductLeasingRequest";

export const productLeasingCreateApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    postProductLeasingCreate: build.mutation<CreateProductLeasingResponse, CreateProductLeasingRequest>({
      query: (queryArg) => ({
        url: `/api/v1/consumer/product-leasing/create`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
});

export const { usePostProductLeasingCreateMutation } = productLeasingCreateApi;

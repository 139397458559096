import { string } from "yup";

export const getFormattedPhoneSchema = (lengthMessage = "Укажите корректный номер телефона") =>
  string()
    .transform((value, initialValue) => {
      return initialValue.replace(/\+7|\D/g, "");
    })
    .test("length", lengthMessage, (value) => {
      return value !== undefined && value.length === 10;
    })
    .required("Укажите номер телефона");

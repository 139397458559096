import { appApi } from "common/api";
import { objectToFormData } from "common/utils/objectToFormData";
import { CreateDemandConsumerCommentRequest } from "./dto/request/CreateDemandConsumerCommentRequest";
import { GetDemandConsumerCommentsListRequest } from "./dto/request/GetDemandConsumerCommentsListRequest";
import { CreateDemandConsumerCommentResponse } from "./dto/response/CreateDemandConsumerCommentResponse";
import { GetDemandConsumerCommentsListResponse } from "./dto/response/GetDemandConsumerCommentsListResponse";

export const demandConsumerCommentsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDemandConsumerComments: build.query<GetDemandConsumerCommentsListResponse, GetDemandConsumerCommentsListRequest>(
      {
        query: (queryArg) => ({
          url: `/api/v1/consumer/comments/demand`,
          params: queryArg,
        }),
      }
    ),
    postDemandConsumerCreateComment: build.mutation<
      CreateDemandConsumerCommentResponse,
      CreateDemandConsumerCommentRequest
    >({
      query: (queryArg) => {
        return {
          url: `/api/v1/consumer/comments/create`,
          method: "POST",
          body: objectToFormData(queryArg),
        };
      },
    }),
  }),
});

export const { useGetDemandConsumerCommentsQuery, usePostDemandConsumerCreateCommentMutation } =
  demandConsumerCommentsApi;

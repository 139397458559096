import { EnumLikeStringLiteralType, makeEnumLikeStringLiteral } from "common/types";

const titles = {
  setUserData: "Данные",
  validatePhone: "Подтверждение телефона",
  setPassword: "Пароль",
};

export const userRegistrationStageType = makeEnumLikeStringLiteral(
  ["setUserData", "validatePhone", "setPassword"] as const,
  titles
);
export type UserRegistrationStageType = EnumLikeStringLiteralType<typeof userRegistrationStageType>;

import { useParams } from "react-router-dom";
import React from "react";
import { QueueTaskId } from "common/types";
import { QueueTasksConveyorDetails } from "queue-tasks/conveyor-details/components/QueueTasksConveyorDetails";
import { ErrorAlert } from "common/ui/alerts";

export const QueueTasksConveyorDetailsPage = () => {
  const { taskId } = useParams<{ taskId: QueueTaskId }>();

  if (!taskId) {
    return <ErrorAlert error="Задача не найдена" />;
  }

  return <QueueTasksConveyorDetails queueTaskId={taskId} />;
};

import { makeAppUrl } from "common/urls/utils/makeAppUrl";
import { AppUrlTypeEnum } from "common/urls/types/AppUrlTypeEnum";
import { UserRoleEnum } from "user/common/types";

export const appUrls = {
  index: makeAppUrl("/", "/", AppUrlTypeEnum.Common),
  demand: {
    index: makeAppUrl("/demand", "/demand", AppUrlTypeEnum.Private),
    details: makeAppUrl("/demand/:demandId", "/:demandId", AppUrlTypeEnum.Private),
  },
  products: {
    index: makeAppUrl("/products", "/products", AppUrlTypeEnum.Private),
    leasing: {
      index: makeAppUrl("/products/leasing", "/leasing", AppUrlTypeEnum.Private, [UserRoleEnum.Consumer]),
      create: makeAppUrl("/products/leasing/create", "/create", AppUrlTypeEnum.Private, [UserRoleEnum.Consumer]),
    },
  },
  user: {
    account: {
      index: makeAppUrl("/account", "/account", AppUrlTypeEnum.Common),
      login: makeAppUrl("/account/login", "/login", AppUrlTypeEnum.Public),
      logout: makeAppUrl("/account/logout", "/logout", AppUrlTypeEnum.Private),
      registration: makeAppUrl("/account/registration", "/registration", AppUrlTypeEnum.Public),
    },
  },
  queueTasks: {
    index: makeAppUrl("/tasks", "/tasks", AppUrlTypeEnum.Private, [UserRoleEnum.ProductManager]),
    details: makeAppUrl("/tasks/:taskId", "/:taskId", AppUrlTypeEnum.Private, [UserRoleEnum.ProductManager]),
  },
};

export type AppUrls = typeof appUrls;

import React, { useState } from "react";
import { useAppSelector } from "common/store";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { commentScrollTitles } from "demand/details/entities/commentScrollTitles";
import { AppInfinityScroll } from "common/ui/lists/infinite-scroll";
import { selectComments, selectCommentsIds } from "demand/comments/store/demandComment.selectors";
import { RefetchErrorAlert } from "common/ui/alerts";
import { QueryError } from "common/api/types/QueryError";
import { CommentId, DemandId } from "common/types";
import {
  useGetDemandConsumerCommentsQuery,
  usePostDemandConsumerCreateCommentMutation,
} from "demand/comments/consumer/api/demandConsumerCommentsApi";
import { DemandComment } from "../../../details/components/DemandComment";
import { DemandCommentItem } from "../../../details/components/DemandCommentItem";

export interface DemandCommentProps {
  demandId: DemandId;
}

export const DemandConsumerCommentWrapper = ({ demandId }: DemandCommentProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [createComment, options] = usePostDemandConsumerCreateCommentMutation();

  const items = useAppSelector(selectCommentsIds);

  const { isLoading, isError, isSuccess, refetch, error } = useGetDemandConsumerCommentsQuery(
    {
      demandId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isError) {
    return (
      <RefetchErrorAlert
        error={error as QueryError}
        errorFallback="Не удалось получить комментарии"
        onRefetchClick={refetch}
      />
    );
  }

  const onNextPageFetching = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handleSubmit = (text: string, files: File[]) => {
    createComment({
      demandCommentLastId: items[0] as CommentId,
      text,
      files,
      demandId,
    });
  };

  return (
    <>
      <Box>
        <Typography variant="h5" component="h2">
          Комментарии по заявке
        </Typography>
        <Divider />
      </Box>
      <DemandComment options={options} handleSubmit={handleSubmit} />
      <AppInfinityScroll
        page={pageNumber}
        paginatedStateSelector={(x) => selectComments(x).pagination}
        idsSelector={selectCommentsIds}
        titles={commentScrollTitles}
        onPageChanged={onNextPageFetching}
        onErrorReloadButtonClick={refetch}
        isFailed={isError}
        isLoading={isLoading}
        isSucceeded={isSuccess}
      >
        <Stack spacing={2}>
          {items.map((x) => {
            return <DemandCommentItem key={x} commentId={x as CommentId} />;
          })}
        </Stack>
      </AppInfinityScroll>
    </>
  );
};

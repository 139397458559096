import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { appApi } from "common/api";
import { removeUserAuth, userAuthReducer } from "user/auth/store/userAuth.slice";
import { demandListReducer } from "demand/list";
import { queueTasksListReducer } from "queue-tasks/list";
import { productLeasingCreateFileReducer } from "product-leasing/create";
import { serviceWorkerReducer } from "application/service-worker";
import { applicationUpdaterReducer } from "application/updater";
import { commentsReducer } from "demand/comments";

const reducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  user: combineReducers({
    auth: userAuthReducer,
  }),
  demand: combineReducers({
    list: demandListReducer,
    comments: commentsReducer,
  }),
  queueTasks: combineReducers({
    list: queueTasksListReducer,
  }),
  productLeasing: combineReducers({
    create: combineReducers({
      file: productLeasingCreateFileReducer,
    }),
  }),
  application: combineReducers({
    serviceWorker: serviceWorkerReducer,
    updater: applicationUpdaterReducer,
  }),
});

export type RootState = ReturnType<typeof reducer>;

// обертка над reducer, чтобы очищать состояние после logout пользователя
export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === removeUserAuth.toString()) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

import React from "react";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { BaseFormTextFieldProps } from "common/ui/types/BaseFormTextFieldProps";

export interface PriceTextFieldProps extends Omit<BaseFormTextFieldProps, "value"> {
  value?: string | number | undefined | null;
}

export const PriceTextField = ({
  label,
  name,
  value,
  helperText,
  error,
  disabled,
  required,
  onBlur,
  onChange,
}: PriceTextFieldProps) => {
  return (
    <NumberFormat
      customInput={TextField}
      decimalSeparator=","
      thousandSeparator=" "
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      margin="normal"
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      autoComplete="off"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      disabled={disabled}
      required={required}
      inputProps={{ inputMode: "decimal" }}
    />
  );
};

import { string } from "yup";
import { taxpayerNumberValidator } from "common/validation/utils/taxpayerNumberValidator";

export const getTaxpayerNumberSchema = () => {
  return string()
    .test(
      "len",
      "ИНН должен быть длиной 10 или 12 символов",
      (value) => !value || value.length === 10 || value.length === 12
    )
    .test("valid", "Укажите корректный ИНН", (value) => !value || taxpayerNumberValidator(value));
};

import { userAuthLocalStorage } from "user/auth/store/userAuth.localStorage";
import { userAuthorized } from "user/auth/store/userAuth.slice";
import { useAppDispatch } from "common/store";
import { UserAuthDetails } from "user/auth/types/UserAuthDetails";
import { useCallback } from "react";

export const useSetUserAuth = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (userAuthDetails: UserAuthDetails) => {
      userAuthLocalStorage.setUser(userAuthDetails);
      dispatch(userAuthorized(userAuthDetails));
    },
    [dispatch]
  );
};

import React from "react";
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { BaseFormTextFieldProps } from "common/ui/types";

export interface PasswordTextFieldProps extends BaseFormTextFieldProps {
  autoComplete?: string;
  showPassword?: boolean;
  onShowPasswordClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const PasswordTextField = ({
  helperText,
  onBlur,
  autoFocus,
  onChange,
  disabled,
  error,
  label,
  name,
  value,
  required,
  autoComplete,
  showPassword,
  onShowPasswordClick,
}: PasswordTextFieldProps) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl error={error} variant="outlined" fullWidth required={required} disabled={disabled}>
      <InputLabel>Пароль</InputLabel>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        label={label}
        autoComplete={autoComplete}
        name={name}
        autoFocus={autoFocus}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onShowPasswordClick}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {helperText !== undefined && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import { appApi } from "common/api";
import { unauthenticatedMiddleware } from "common/store/middlewares/unauthenticatedMiddleware";
import { rootReducer } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appApi.middleware, unauthenticatedMiddleware),
});

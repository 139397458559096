import { Button } from "@mui/material";
import { QueryError } from "common/api/types/QueryError";
import React from "react";
import { ErrorAlert } from "common/ui/alerts/components/ErrorAlert";

export interface RefetchErrorAlertProps {
  error: QueryError | undefined;
  errorFallback?: string;
  onRefetchClick: () => void | Promise<void>;
  title?: string;
}

export const RefetchErrorAlert = ({ error, errorFallback, onRefetchClick, title }: RefetchErrorAlertProps) => {
  return (
    <ErrorAlert
      title={title}
      error={
        <>
          {error?.data?.message ?? errorFallback}
          <Button type="button" onClick={onRefetchClick} size="small" variant="text" sx={{ padding: 0, marginLeft: 2 }}>
            Повторить
          </Button>
        </>
      }
    />
  );
};

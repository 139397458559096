import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceWorkerState } from "application/service-worker/store/serviceWorker.types";
import { combineSliceNames } from "common/store/utils";
import { APPLICATION_SLICE_NAME } from "application/common/store";

const SERVICE_WORKER_SLICE_NAME = combineSliceNames(APPLICATION_SLICE_NAME, "serviceWorker");

const initialState: ServiceWorkerState = {};

const slice = createSlice({
  name: SERVICE_WORKER_SLICE_NAME,
  initialState,
  reducers: {
    serviceWorkerInitialised: (state) => {
      state.isInitialized = true;
    },
    serviceWorkerUpdated: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
      state.isUpdated = true;
      state.registration = action.payload;
    },
  },
});

export const serviceWorkerReducer = slice.reducer;
export const { serviceWorkerInitialised, serviceWorkerUpdated } = slice.actions;

import { RootState } from "common/store";
import { productLeasingCreateFilesAdapter } from "product-leasing/create/store/productLeasingCreateFiles.adapter";
import { createSelector } from "@reduxjs/toolkit";

export const selectProductLeasingCreateFile = (state: RootState) => state.productLeasing.create.file;
export const { selectIds: selectProductLeasingCreateFileIds, selectById: selectProductLeasingCreateFileById } =
  productLeasingCreateFilesAdapter.getSelectors(selectProductLeasingCreateFile);

export const selectProductLeasingCreateUploadedFileIds = createSelector(
  [(state) => selectProductLeasingCreateFile(state)],
  (state) => {
    return state.ids.filter((x) => state.entities[x]?.isUploaded === true) as string[];
  }
);

import { appApi, EmptyResponse } from "common/api";
import { GetQueueTasksConveyorDetailsResponse } from "queue-tasks/conveyor-details/api/dto/response/GetQueueTasksConveyorDetailsResponse";
import { GetQueueTasksConveyorDetailsRequest } from "queue-tasks/conveyor-details/api/dto/request/GetQueueTasksConveyorDetailsRequest";
import { SendQueueTasksConveyorDetailsDecisionRequest } from "queue-tasks/conveyor-details/api/dto/request/SendQueueTasksConveyorDetailsDecisionRequest";

export const queueTasksConveyorDetailsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getQueueTasksConveyorDetails: build.query<
      GetQueueTasksConveyorDetailsResponse,
      GetQueueTasksConveyorDetailsRequest
    >({
      query: (queryArg) => ({
        url: `/api/v1/conveyor/queue-tasks/details`,
        params: queryArg,
      }),
      keepUnusedDataFor: 30,
    }),
    sendQueueTasksConveyorDetailsDecision: build.mutation<EmptyResponse, SendQueueTasksConveyorDetailsDecisionRequest>({
      query: (queryArg) => ({
        url: `/api/v1/conveyor/queue-tasks/send`,
        method: "PUT",
        params: queryArg,
      }),
    }),
  }),
});

export const { useGetQueueTasksConveyorDetailsQuery, useSendQueueTasksConveyorDetailsDecisionMutation } =
  queueTasksConveyorDetailsApi;

import { combineSliceNames } from "common/store/utils";
import { APPLICATION_SLICE_NAME } from "application/common/store";
import { ApplicationUpdaterState } from "application/updater/store/applicationUpdater.types";
import { createSlice } from "@reduxjs/toolkit";

export const APPLICATION_UPDATER_SLICE_NAME = combineSliceNames(APPLICATION_SLICE_NAME, "updater");

const initialState: ApplicationUpdaterState = {
  lastUpdateTime: Date.now(),
  needRefresh: false,
};

const slice = createSlice({
  name: APPLICATION_UPDATER_SLICE_NAME,
  initialState,
  reducers: {
    applicationUpdateChecked(state, { payload }: { payload: { needRefresh: boolean; updateTime: number } }) {
      state.needRefresh = payload.needRefresh;
      state.lastUpdateTime = payload.updateTime;
    },
  },
});

export const { applicationUpdateChecked } = slice.actions;
export const applicationUpdaterReducer = slice.reducer;

import React from "react";
import { DemandId } from "common/types";
import { useAppSelector } from "common/store";
import { selectDemandListItemById } from "demand/list/store/demandList.selectors";
import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { generatePath } from "common/navigation/utils/generatePath";
import { dateTimeHelper, specialFormatters } from "common/helpers/date";
import { currencyHelper } from "common/helpers/number";
import { entityHelper } from "common/helpers/entity";
import { DemandStatusTitle } from "demand/common/components/DemandStatusTitle";
import { selectUserAuth } from "user/auth";
import CircleIcon from "@mui/icons-material/Circle";
import { NavigationState } from "common/navigation/navigation-state";

export interface DemandListItemProps {
  id: DemandId;
  from?: NavigationState;
}

export const DemandListItem = ({ id, from }: DemandListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const demand = useAppSelector((state) => selectDemandListItemById(state, id))!;
  const { user } = useAppSelector(selectUserAuth);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box mb={2}>
          <Typography sx={{ fontSize: 14, lineHeight: 1 }} color="text.secondary" gutterBottom>
            Заявка {demand.demandNumber ?? demand.demandId} от&nbsp;
            {dateTimeHelper.format(new Date(demand.dateCreated), specialFormatters.dayFullMonthYearIfDifferentAndTime)}
          </Typography>
          {user?.isConsumer && demand.isWithActiveAgencyTask && (
            <Typography sx={{ fontSize: 14, lineHeight: 1 }} variant="caption" color="error.light" gutterBottom>
              <CircleIcon sx={{ fontSize: 10 }} color="error" /> Требуется действие
            </Typography>
          )}
          <DemandStatusTitle
            sx={{ fontSize: 14, lineHeight: 1 }}
            isSuccess={demand.isSuccess}
            isApproved={demand.isApproved}
            isFailure={demand.isFailure}
          />
        </Box>
        <Typography variant="h6" component="div">
          {demand.clientName}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {demand.clientTaxpayerNumber}
        </Typography>
        {demand.leasing && (
          <Stack direction="column" spacing={0.5}>
            <Typography variant="body2">
              лизинг на сумму {currencyHelper.getCurrencyString(demand.leasing.sum)}
            </Typography>
            <Typography variant="body2">
              срок&nbsp;
              {demand.leasing.periodInMonths > 0
                ? entityHelper.getWordDeclension(demand.leasing.periodInMonths, "месяц", ["", "а", "ев"])
                : entityHelper.getWordDeclension(demand.leasing.periodInDays, "д", ["ень", "ня", "ней"])}
            </Typography>
          </Stack>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={generatePath((x) => x.demand.details, { demandId: id })}
          state={from}
          endIcon={<ArrowRightAltIcon />}
        >
          Перейти
        </Button>
      </CardActions>
    </Card>
  );
};

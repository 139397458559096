import { filtrationService, useSetQuerySearch } from "common/filters";
import { useCallback } from "react";
import { DemandListFilterParams } from "../types/DemandListFilterParams";

export type DemandListQueryMutator = <TFilter extends DemandListFilterParams, TKey extends keyof TFilter>(
  filteringObject: TFilter,
  key: TKey,
  value: TFilter[TKey]
) => void;

export const useDemandListFilterMutator = (): DemandListQueryMutator => {
  const setQuery = useSetQuerySearch();

  return useCallback(
    <TFilter extends DemandListFilterParams, TKey extends keyof TFilter>(
      filteringObject: TFilter,
      key: TKey,
      value: TFilter[TKey]
    ) => {
      const diff = {} as TFilter;
      diff[key] = value;

      if (key !== "pageNumber") {
        diff.pageNumber = 1;
      }

      setQuery(filtrationService.setValues(filteringObject, diff));
    },
    [setQuery]
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface EnumLikeStringLiteral<T extends string> {
  values: T[];
  getTitle: (value: T) => string;
}

export const makeEnumLikeStringLiteral = <T extends string>(
  values: readonly T[],
  titles: Record<T, string>
): EnumLikeStringLiteral<T> => {
  return {
    values: Array.from(values),
    getTitle: (value) => titles[value],
  };
};

export type EnumLikeStringLiteralType<T extends EnumLikeStringLiteral<any>> = T extends EnumLikeStringLiteral<infer U>
  ? U
  : never;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Divider, Link, Menu, MenuItem, Theme, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { AppMenu } from "common/navigation/menu";
import { BrandLogo } from "common/ui/theme/BrandLogo";
import { Link as RouterLink } from "react-router-dom";
import { selectAppUrl } from "common/urls";
import { useAppNavigation } from "common/navigation/hooks/useAppNavigation";
import { useAppSelector } from "common/store";
import { selectUserAuth } from "user/auth";

export interface AppLayoutProps {
  children?: React.ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const auth = useAppSelector(selectUserAuth);
  const navigator = useAppNavigation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    navigator((x) => x.user.account.logout);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={(theme) => ({
          width: { sm: `calc(100% - ${theme.layout.asideWidth}px)` },
          ml: { sm: `${theme.layout.asideWidth}px` },
          backgroundColor: theme.customColors.dark,
        })}
      >
        <Toolbar sx={{ justifyContent: { xs: "space-between", sm: "flex-end" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <IconButton edge="end" color="inherit" onClick={handleMenu}>
              <PersonIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {auth.user && <MenuItem>{auth.user?.username}</MenuItem>}
              {auth.user && <Divider />}
              <MenuItem onClick={onLogoutClick}>Выйти</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="aside"
        sx={(theme) => ({ width: { sm: theme.layout.asideWidth }, flexShrink: { sm: 0 } })}
        aria-label="mailbox folders"
      >
        <Drawer
          container={isSm ? container : undefined}
          variant={isSm ? "temporary" : "permanent"}
          open={isSm ? mobileOpen : true}
          onClose={isSm ? handleDrawerToggle : undefined}
          ModalProps={{
            keepMounted: true,
          }}
          sx={(theme) => ({
            display: { xs: isSm ? "block" : "none", sm: isSm ? "none" : "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: theme.layout.asideWidth,
              borderRight: "none",
              backgroundColor: theme.palette.grey[100],
            },
          })}
        >
          <Toolbar sx={(theme) => ({ backgroundColor: theme.customColors.dark })}>
            <Link component={RouterLink} to={selectAppUrl((x) => x.index).url}>
              <BrandLogo />
            </Link>
          </Toolbar>

          <List>
            <AppMenu />
          </List>
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 5, width: { xs: "100%", xl: "1200px" } }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

import { Stack, Typography } from "@mui/material";
import React from "react";

interface QueueTasksListItemDlProps {
  title: string;
  value: string;
}

export const QueueTasksListItemDl = ({ value, title }: QueueTasksListItemDlProps) => {
  return (
    <Stack direction="row" spacing={0.5}>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="caption" fontWeight={500}>
        {value}
      </Typography>
    </Stack>
  );
};

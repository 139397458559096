import { appApi } from "common/api";
import { objectToFormData } from "common/utils/objectToFormData";
import { CreateDemandConveyorCommentRequest } from "./dto/request/CreateDemandConveyorCommentRequest";
import { GetDemandConveyorCommentsListRequest } from "./dto/request/GetDemandConveyorCommentsListRequest";
import { CreateDemandConveyorCommentResponse } from "./dto/response/CreateDemandConveyorCommentResponse";
import { GetDemandConveyorCommentsListResponse } from "./dto/response/GetDemandConveyorCommentsListResponse";

export const demandConveyorCommentsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDemandConveyorComments: build.query<GetDemandConveyorCommentsListResponse, GetDemandConveyorCommentsListRequest>(
      {
        query: (queryArg) => ({
          url: `/api/v1/conveyor/comments/demand`,
          params: queryArg,
        }),
      }
    ),
    postDemandConveyorCreateComment: build.mutation<
      CreateDemandConveyorCommentResponse,
      CreateDemandConveyorCommentRequest
    >({
      query: (queryArg) => {
        return {
          url: `/api/v1/conveyor/comments/create`,
          method: "POST",
          body: objectToFormData(queryArg),
        };
      },
    }),
  }),
});

export const { useGetDemandConveyorCommentsQuery, usePostDemandConveyorCreateCommentMutation } =
  demandConveyorCommentsApi;

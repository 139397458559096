import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "common/store";
import { selectApplicationUpdater } from "application/updater/store/applicationUpdater.selectors";
import { applicationUpdateChecked } from "application/updater/store/applicationUpdater.slice";
import { useLazyApplicationUpdaterGetVersionQuery } from "application/updater/api/applicationUpdaterApi";
import { useLocationPathEffect } from "common/hooks";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { applicationUpdaterConfig } from "application/updater/configs";
import { applicationConfig } from "application/common/configs";

const isDifferentVersions = (actualVersion: string | undefined, fetchedVersion: string | undefined) => {
  if (!actualVersion || !fetchedVersion) {
    return false;
  }

  return actualVersion !== fetchedVersion;
};

const isTimeExpired = (lastUpdateTime: number, timeDifference: number) => {
  const diff = Date.now() - lastUpdateTime;
  return diff >= timeDifference;
};

export const useApplicationUpdater = () => {
  const TIME_DIFF_ON_SUCCESS = applicationUpdaterConfig.updateIntervalInMinutes * 60 * 1000;

  const dispatch = useAppDispatch();
  const [trigger, { data, isLoading, status }] = useLazyApplicationUpdaterGetVersionQuery();

  const { lastUpdateTime, needRefresh } = useAppSelector(selectApplicationUpdater);

  useLocationPathEffect(() => {
    if (!needRefresh && !isLoading && isTimeExpired(lastUpdateTime, TIME_DIFF_ON_SUCCESS)) {
      trigger(undefined, false);
    }
  }, [TIME_DIFF_ON_SUCCESS, isLoading, lastUpdateTime, needRefresh, trigger]);

  useEffect(() => {
    if (status === QueryStatus.pending || status === QueryStatus.fulfilled) {
      const newVersionExists = data?.currentVersion
        ? isDifferentVersions(applicationConfig.version, data?.currentVersion)
        : false;
      dispatch(applicationUpdateChecked({ updateTime: Date.now(), needRefresh: newVersionExists }));
    }
  }, [data, dispatch, status]);

  return needRefresh;
};

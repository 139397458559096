import React, { useMemo } from "react";
import { AppRouteUserRoleElement } from "common/routes/types/AppRoute";
import { useAppSelector } from "common/store";
import { selectUserAuth } from "user/auth";

export interface AppRoutePermissionElementProps {
  permissionElement: AppRouteUserRoleElement;
  baseElement: React.ReactNode;
}

export const AppRoutePermissionElement = ({ baseElement, permissionElement }: AppRoutePermissionElementProps) => {
  const { user } = useAppSelector(selectUserAuth);

  const element = useMemo(() => {
    if (user?.roles?.values) {
      const permission = user.roles.values.find((x) => permissionElement[x] !== undefined);
      return permission ? permissionElement[permission] : baseElement;
    }

    return baseElement;
  }, [baseElement, permissionElement, user?.roles.values]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};

import React, { useCallback } from "react";
import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { selectAppUrl } from "common/urls";
import AddIcon from "@mui/icons-material/Add";
import { DemandListQueryMutator } from "demand/list/hooks/useDemandListFilterMutator";
import { DemandListFilterParams } from "demand/list/types/DemandListFilterParams";
import { demandListTab } from "demand/list/types/DemandListTabType";
import { DemandListFilterSearchTextField } from "demand/list/components/DemandListFilterSearchTextField";

export interface DemandListHeaderProps {
  filteringObject: DemandListFilterParams;
  mutator: DemandListQueryMutator;
  canCreateNewDemand: boolean;
}

export const DemandListHeader = ({ filteringObject, mutator, canCreateNewDemand }: DemandListHeaderProps) => {
  const onSearchByClientChanged = useCallback(
    (value) => {
      if (filteringObject.searchByClient !== value) {
        mutator(filteringObject, "searchByClient", value);
      }
    },
    [mutator, filteringObject]
  );

  const onSearchByDemandChanged = useCallback(
    (value) => {
      if (filteringObject.searchByDemand !== value) {
        mutator(filteringObject, "searchByDemand", value);
      }
    },
    [mutator, filteringObject]
  );

  return (
    <Stack spacing={2} component="header">
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
      >
        <Stack spacing={2} direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-start", md: "center" }}>
          <Typography variant="h3" component="h2" fontWeight="400">
            Мои заявки
          </Typography>
          <ToggleButtonGroup
            value={filteringObject.tab}
            exclusive
            size="small"
            onChange={(e, value) => {
              value && mutator(filteringObject, "tab", value);
            }}
            aria-label="text alignment"
          >
            {demandListTab.values.map((x) => (
              <ToggleButton value={x} key={x}>
                {demandListTab.getTitle(x)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
        {canCreateNewDemand && (
          <Button
            variant="contained"
            component={Link}
            to={selectAppUrl((x) => x.products.leasing.create).url}
            startIcon={<AddIcon />}
            color="success"
            sx={{ display: { xs: "none", md: "inline-flex" } }}
          >
            Новая заявка
          </Button>
        )}
      </Stack>
      <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
        <DemandListFilterSearchTextField
          value={filteringObject.searchByClient}
          label="Клиент или ИНН"
          onChange={onSearchByClientChanged}
        />
        <DemandListFilterSearchTextField
          value={filteringObject.searchByDemand}
          label="Номер заявки"
          onChange={onSearchByDemandChanged}
        />
      </Stack>
    </Stack>
  );
};

import { appApi } from "common/api";
import { GetDemandListConsumerResponse } from "demand/list/api/dto/response/GetDemandListConsumerResponse";
import { GetDemandListConsumerRequest } from "demand/list/api/dto/request/GetDemandListConsumerRequest";

export const demandListApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDemandListConsumer: build.query<GetDemandListConsumerResponse, GetDemandListConsumerRequest>({
      query: (queryArg) => ({
        url: `/api/v1/consumer/demands/get`,
        params: queryArg,
      }),
    }),
    getDemandListConveyor: build.query<GetDemandListConsumerResponse, GetDemandListConsumerRequest>({
      query: (queryArg) => ({
        url: `/api/v1/conveyor/demands/get`,
        params: queryArg,
      }),
    }),
  }),
});

export const { useGetDemandListConsumerQuery, useGetDemandListConveyorQuery } = demandListApi;

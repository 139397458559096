import { InferType, object, string } from "yup";
import { getFormattedPhoneSchema, getSchemaKeys } from "common/validation";

export const userRegistrationAddUserFormValidationSchema = object({
  contactPhoneNumber: getFormattedPhoneSchema().default("").required("Укажите номер телефона"),
  contactEmail: string().nullable(true).default("").email("Укажите корректную электронную почту").notRequired(),
  userName: string().default("").required("Не забудьте заполнить"),
});

export type UserRegistrationAddUserFormValues = InferType<typeof userRegistrationAddUserFormValidationSchema>;

export const userRegistrationAddUserFormValueKeys = getSchemaKeys(userRegistrationAddUserFormValidationSchema);

import {useEffect} from "react";

/**
 * Устанавливает значение с задержкой.
 * Необходимо, например, для фильтрации с асинхронными запросами в апи, чтобы не запрашивать информацию при вводе каждого символа
 * @param value Новое значение
 * @param setValue Функция, устанавливающая значение. Будет вызвана, если `value` не изменялось более чем за `delay`
 * @param delay Задержка перед изменением состояния, мс
 */
export const useStateDebounce = <T>(value: T, setValue: (value: T) => void, delay = 500) => {
  useEffect(() => {
    const timer = setTimeout(() => setValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, setValue, value]);
};

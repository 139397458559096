import { UserAuthState } from "user/auth/store/userAuth.types";
import { userAuthLocalStorage } from "user/auth/store/userAuth.localStorage";
import { createSlice } from "@reduxjs/toolkit";
import { combineSliceNames } from "common/store/utils";
import { USER_SLICE_NAME } from "user/common/store";
import { UserAuthDetails } from "user/auth/types/UserAuthDetails";

const authorizedUser = userAuthLocalStorage.getUser();

const initialState: UserAuthState = {
  isAuthorized: !!authorizedUser,
  user: authorizedUser,
};

const USER_AUTH_SLICE_NAME = combineSliceNames(USER_SLICE_NAME, "auth");

const slice = createSlice({
  name: USER_AUTH_SLICE_NAME,
  initialState,
  reducers: {
    removeUserAuth(state) {
      state.isAuthorized = false;
      state.user = undefined;
    },
    userAuthorized(state, action: { payload: UserAuthDetails }) {
      state.isAuthorized = true;
      state.user = action.payload;
    },
  },
});

export const { removeUserAuth, userAuthorized } = slice.actions;
export const userAuthReducer = slice.reducer;

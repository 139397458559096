import { appApi } from "common/api";
import { GetDemandConsumerDetailsRequest } from "demand/consumer-details/api/dto/request/GetDemandConsumerDetailsRequest";
import { GetDemandConsumerDetailsResponse } from "demand/consumer-details/api/dto/response/GetDemandConsumerDetailsResponse";

export const demandConsumerDetailsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDemandConsumerDetails: build.query<GetDemandConsumerDetailsResponse, GetDemandConsumerDetailsRequest>({
      query: (queryArg) => ({
        url: `/api/v1/consumer/demands/details`,
        params: queryArg,
      }),
      keepUnusedDataFor: 30,
    }),
  }),
});

export const { useGetDemandConsumerDetailsQuery } = demandConsumerDetailsApi;

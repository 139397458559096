import { usePutUserAuthLogoutMutation } from "user/auth/api/userAuthApi";
import { useCallback } from "react";
import { userAuthLocalStorage } from "user/auth/store/userAuth.localStorage";
import { store } from "common/store";
import { removeUserAuth } from "user/auth/store/userAuth.slice";

export const useUserAuthLogout = () => {
  const [logout, options] = usePutUserAuthLogoutMutation();

  const requestFunc = useCallback(async () => {
    try {
      await logout(undefined).unwrap();
      userAuthLocalStorage.removeUser();
      store.dispatch(removeUserAuth());
    } catch (err) {
      console.log(err);
    }
  }, [logout]);

  return [requestFunc, options] as const;
};

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppUrlTypeEnum, selectAppUrl } from "common/urls";
import { useAppSelector } from "common/store";
import { selectUserAuth } from "user/auth";
import { NavigationState, useNavigationStateFrom } from "common/navigation/navigation-state";
import { AnyAppUrl } from "common/urls/types/AppUrl";
import { AnyIndexAppUrl } from "common/urls/types/IndexAppUrl";

export interface AppRouteElementProps {
  children: React.ReactNode;
  url: AnyAppUrl | AnyIndexAppUrl;
}

export const AppRouteElement = ({ children, url }: AppRouteElementProps): JSX.Element => {
  const { isAuthorized, user } = useAppSelector(selectUserAuth);
  const location = useLocation();

  const state = useNavigationStateFrom();
  const stateFrom: NavigationState | undefined =
    location.pathname === selectAppUrl((x) => x.user.account.logout).url
      ? undefined
      : { from: { url: location.pathname + location.search } };

  if (!isAuthorized && url.urlType === AppUrlTypeEnum.Private) {
    return <Navigate to={selectAppUrl((x) => x.user.account.login).url} state={stateFrom} replace />;
  }

  if (isAuthorized && url.urlType === AppUrlTypeEnum.Public) {
    return <Navigate to={state?.from?.url ?? selectAppUrl((x) => x.index).url} replace />;
  }

  if (
    isAuthorized &&
    url.permission.length > 0 &&
    (!user || !url.permission.some((x) => user.roles.values.includes(x)))
  ) {
    return <Navigate to={selectAppUrl((x) => x.index).url} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

import { filtrationService, useSetQuerySearch } from "common/filters";
import { useCallback } from "react";
import { QueueTasksListFilterParams } from "queue-tasks/list/types/QueueTasksListFilterParams";

export type QueueTasksListQueryMutator = <TFilter extends QueueTasksListFilterParams, TKey extends keyof TFilter>(
  filteringObject: TFilter,
  key: TKey,
  value: TFilter[TKey]
) => void;

export const useQueueTasksListFilterMutator = (): QueueTasksListQueryMutator => {
  const setQuery = useSetQuerySearch();

  return useCallback(
    <TFilter extends QueueTasksListFilterParams, TKey extends keyof TFilter>(
      filteringObject: TFilter,
      key: TKey,
      value: TFilter[TKey]
    ) => {
      const diff = {} as TFilter;
      diff[key] = value;

      if (key !== "pageNumber") {
        diff.pageNumber = 1;
      }

      setQuery(filtrationService.setValues(filteringObject, diff));
    },
    [setQuery]
  );
};

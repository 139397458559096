import React from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, grey } from "@mui/material/colors";
import { dateTimeHelper, specialFormatters } from "common/helpers/date";

export interface ProductLeasingCreateFileCardProps {
  name: string;
  status?: QueryStatus;
  downloadLink?: string;
  dateCreated?: Date;
  onDelete?: () => void | Promise<void>;
}

export const FileCard = ({ name, status, downloadLink, dateCreated, onDelete }: ProductLeasingCreateFileCardProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <Box
        flexGrow={1}
        flexShrink={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {!downloadLink && (
          <Box
            component="span"
            sx={(theme) => ({
              background: grey[300],
              color: theme.palette.text.secondary,
              padding: theme.spacing(0.5),
              fontSize: "0.9em",
              borderRadius: 1,
            })}
          >
            {name}
          </Box>
        )}
        {downloadLink && (
          <Box
            component="a"
            target="_blank"
            href={downloadLink}
            sx={(theme) => ({
              background: blue[200],
              color: theme.palette.info.contrastText,
              padding: theme.spacing(0.5),
              fontSize: "0.9em",
              borderRadius: 1,
              textDecoration: "none",
              "&: hover": {
                background: blue[300],
                textDecoration: "underline",
              },
            })}
          >
            {name}
          </Box>
        )}
      </Box>
      {status && (
        <Typography variant="caption" color={status === QueryStatus.rejected ? "error" : "text.secondary"}>
          {status === QueryStatus.pending && (
            <>
              <Box component="span" display={{ xs: "none", md: "inline" }}>
                Загружается
              </Box>
              <CircularProgress color="inherit" size={10} sx={{ marginLeft: 1, marginRight: 1 }} />
            </>
          )}
          {status === QueryStatus.rejected && "Ошибка загрузки"}
        </Typography>
      )}
      {dateCreated && (
        <Typography variant="caption" color="text.secondary">
          Прикреплен {dateTimeHelper.format(dateCreated, specialFormatters.dayFullMonthYearIfDifferentAndTime)}
        </Typography>
      )}
      {onDelete && (
        <IconButton aria-label="delete" size="small" color="error" type="button" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

import { UserAuthDetails } from "user/auth/types/UserAuthDetails";
import { jsonService } from "common/services/json";

const USER_KEY = "_user";

const getUser = (): UserAuthDetails | undefined => {
  const serializedUser = localStorage.getItem(USER_KEY);
  return serializedUser ? jsonService.deserialize(serializedUser) : undefined;
};

const setUser = (user: UserAuthDetails) => user && localStorage.setItem(USER_KEY, jsonService.serialize(user));
const removeUser = () => localStorage.removeItem(USER_KEY);

export const userAuthLocalStorage = {
  getUser,
  setUser,
  removeUser,
};

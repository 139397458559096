import { EnumCollection } from "common/types";
import { UserRoleEnum } from "user/common/types";

export interface UserAuthDetails {
  login: string;
  username: string;
  roles: EnumCollection<UserRoleEnum>;
  isConsumer: boolean;
  isConveyor: boolean;
}

export const makeUserAuthDetails = (
  login: string,
  username: string,
  roles: EnumCollection<UserRoleEnum>
): UserAuthDetails => {
  const isConsumer = roles.values.includes(UserRoleEnum.Consumer);

  return {
    login,
    username,
    roles,
    isConsumer,
    isConveyor: !isConsumer,
  };
};

import { InferType, number, object } from "yup";
import { getSchemaDefaultKeys } from "common/validation";

export const queueTasksListFiltersValidationSchema = object({
  pageNumber: number().default(1),
});

export type QueueTasksListFilterParams = InferType<typeof queueTasksListFiltersValidationSchema>;

export const queueTasksListFilterParamsDefaultKeys = getSchemaDefaultKeys(queueTasksListFiltersValidationSchema);

import { appApi } from "common/api";
import { GetDemandConveyorDetailsResponse } from "demand/conveyor-details/api/dto/response/GetDemandConveyorDetailsResponse";
import { GetDemandConveyorDetailsRequest } from "demand/conveyor-details/api/dto/request/GetDemandConveyorDetailsRequest";

export const demandConveyorDetailsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDemandConveyorDetails: build.query<GetDemandConveyorDetailsResponse, GetDemandConveyorDetailsRequest>({
      query: (queryArg) => ({
        url: `/api/v1/conveyor/demands/details`,
        params: queryArg,
      }),
      keepUnusedDataFor: 30,
    }),
  }),
});

export const { useGetDemandConveyorDetailsQuery } = demandConveyorDetailsApi;

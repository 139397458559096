import { Box, Typography } from "@mui/material";
import React from "react";

export interface DemandDetailsItemProps {
  title: string;
  description: React.ReactNode;
}

export const DemandDetailsItem = ({ title, description }: DemandDetailsItemProps) => {
  return (
    <Box>
      <Typography component="dt" variant="body2">
        {title}
      </Typography>
      <Typography component="dd" variant="subtitle2">
        {description}
      </Typography>
    </Box>
  );
};

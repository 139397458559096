import React, { useState } from "react";
import { UserId } from "common/types";
import {
  usePostUserRegistrationSendVerificationCodeMutation,
  usePostUserRegistrationVerifyVerificationCodeMutation,
} from "user/registration/api/userRegistrationApi";
import { useVerificationCodeCountdown } from "user/registration/hooks/useVerificationCodeCountdown";
import { useFormik } from "formik";
import {
  userRegistrationVerificationCodeFormValidationSchema,
  UserRegistrationVerificationCodeFormValues,
} from "user/registration/types/UserRegistrationVerificationCodeFormValues";
import { Alert, Button, Stack, TextField, Typography } from "@mui/material";
import NumberFormat from "react-number-format";
import { entityHelper } from "common/helpers/entity";
import { QueryError } from "common/api/types/QueryError";

export interface UserRegistrationVerificationCodeFormProps {
  phoneNumber: string;
  userId: UserId;
  onSuccess: () => void | Promise<void>;
}

export const UserRegistrationVerificationCodeForm = ({
  phoneNumber,
  userId,
  onSuccess,
}: UserRegistrationVerificationCodeFormProps) => {
  const [verify] = usePostUserRegistrationVerifyVerificationCodeMutation();
  const [sendAgain, sendAgainOptions] = usePostUserRegistrationSendVerificationCodeMutation();

  const [canSendAgain, setCanSendAgain] = useState(false);
  const secondsCountdown = useVerificationCodeCountdown(canSendAgain, () => setCanSendAgain(true));

  const form = useFormik<UserRegistrationVerificationCodeFormValues>({
    initialValues: userRegistrationVerificationCodeFormValidationSchema.getDefault(),
    validationSchema: userRegistrationVerificationCodeFormValidationSchema,
    onSubmit: async (values, helpers) => {
      const { code } = userRegistrationVerificationCodeFormValidationSchema.cast(values);
      const response = await verify({ code, userId });
      if ((response as { error: QueryError }).error) {
        helpers.setFieldError("code", "Проверьте введенный код");
      } else {
        onSuccess();
      }
    },
  });

  const onSendAgainCodeClick = async () => {
    const response = await sendAgain({ userId });
    if ((response as { data: unknown }).data) {
      setCanSendAgain(false);
    }
  };

  return (
    <Stack component="form" onSubmit={form.handleSubmit} noValidate spacing={2} sx={{ width: "100%" }} mt={2}>
      <Typography variant="caption" textAlign="center">
        На телефон {entityHelper.formatPhoneNumber(phoneNumber)} отправлено сообщение с кодом подтверждения
      </Typography>
      <NumberFormat
        format="# # # # # #"
        mask="_"
        customInput={TextField}
        margin="normal"
        required
        fullWidth
        variant="outlined"
        label="Код подтверждения"
        name="code"
        autoComplete="one-time-code"
        autoFocus
        value={form.values.code}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.code && Boolean(form.errors.code)}
        helperText={form.touched.code && form.errors.code}
        disabled={form.isSubmitting}
        inputProps={{ style: { textAlign: "center" }, inputMode: "numeric" }}
        allowEmptyFormatting
      />

      {sendAgainOptions.isError && <Alert severity="error">Не удалось повторно запросить код</Alert>}
      {(!form.isValid || !form.dirty) && (
        <Stack alignItems="center">
          {secondsCountdown && secondsCountdown > -1 && (
            <Typography variant="caption">
              Вы сможете повторно запросить код через&nbsp;
              {entityHelper.getWordDeclension(secondsCountdown, "секунд", ["у", "ы", ""])}
            </Typography>
          )}
          <Button
            type="button"
            variant="text"
            size="small"
            color="info"
            disabled={!canSendAgain && !sendAgainOptions.isLoading}
            onClick={onSendAgainCodeClick}
          >
            Запросить код повторно
          </Button>
        </Stack>
      )}

      {form.isValid && form.dirty && (
        <Button type="submit" fullWidth variant="contained" color="error" disabled={form.isSubmitting}>
          Подтвердить
        </Button>
      )}
    </Stack>
  );
};

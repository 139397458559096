import { combineSliceNames } from "common/store/utils";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { PRODUCT_LEASING_SLICE_NAME } from "product-leasing/common/store";
import { filesUploadApi } from "files/upload";
import { productLeasingCreateFilesAdapter } from "product-leasing/create/store/productLeasingCreateFiles.adapter";
import { productLeasingCreateFilesMapper } from "product-leasing/create/store/productLeasingCreateFiles.mapper";
import { QueryStatus } from "@reduxjs/toolkit/query";

const initialState = productLeasingCreateFilesAdapter.getInitialState();
const PRODUCT_LEASING_CREATION_FILES_SLICE_NAME = combineSliceNames(PRODUCT_LEASING_SLICE_NAME, "creationFiles");

const slice = createSlice({
  name: PRODUCT_LEASING_CREATION_FILES_SLICE_NAME,
  initialState,
  reducers: {
    clear: () => initialState,
    removeFile: (state, action: PayloadAction<{ id: string }>) => {
      productLeasingCreateFilesAdapter.removeOne(state, action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(filesUploadApi.endpoints.uploadFilesConsumer.matchPending, (state, action) => {
        productLeasingCreateFilesAdapter.addMany(
          state,
          Array.from(action.meta.arg.originalArgs.files).map((x) =>
            productLeasingCreateFilesMapper.fromLocal(action.meta.requestId, QueryStatus.pending, x)
          )
        );
      })
      .addMatcher(
        isAnyOf(
          filesUploadApi.endpoints.uploadFilesConsumer.matchRejected,
          filesUploadApi.endpoints.uploadFilesConsumer.matchFulfilled
        ),
        (state, action) => {
          const ids = state.ids.filter((x) => state.entities[x]?.requestId === action.meta.requestId);

          if (filesUploadApi.endpoints.uploadFilesConsumer.matchFulfilled(action)) {
            productLeasingCreateFilesAdapter.removeMany(state, ids);
          } else {
            productLeasingCreateFilesAdapter.updateMany(
              state,
              ids.map((x) => ({ id: x, changes: { status: QueryStatus.rejected } }))
            );
          }
        }
      )
      .addMatcher(filesUploadApi.endpoints.uploadFilesConsumer.matchFulfilled, (state, action) => {
        productLeasingCreateFilesAdapter.addMany(
          state,
          action.payload.files.map((x) => productLeasingCreateFilesMapper.formExists(x))
        );
      });
  },
});

export const { clear: productLeasingCreateFileClear, removeFile: productLeasingCreateFileRemove } = slice.actions;
export const productLeasingCreateFileReducer = slice.reducer;

import { useCallback } from "react";

export const useServiceWorkerUpdater = () => {
  return useCallback((registration: ServiceWorkerRegistration) => {
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (event) => {
        const sw = event?.target as ServiceWorker;
        if (sw?.state === "activated") {
          window.location.reload();
        }
      });
    }
  }, []);
};

import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UserRegistrationVerificationCodeForm } from "user/registration/components/UserRegistrationVerificationCodeForm";
import { UserId } from "common/types";
import { Box, Button, Stack, Typography } from "@mui/material";
import { UserRegistrationAddUserForm } from "user/registration/components/UserRegistrationAddUserForm";
import { UserRegistrationAddPasswordForm } from "user/registration/components/UserRegistrationAddPasswordForm";
import {
  userRegistrationStageType,
  UserRegistrationStageType,
} from "user/registration/types/UserRegistrationStageType";
import { UserAuthDetails, useSetUserAuth } from "user/auth";
import { selectAppUrl } from "common/urls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const bull = (
  <Box component="span" sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}>
    •
  </Box>
);

export const UserRegistrationPage = () => {
  const [userId, setUserId] = useState<UserId | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [activeStage, setActiveStage] = useState<UserRegistrationStageType>("setUserData");
  const setUserAuth = useSetUserAuth();

  const onAddUserSuccess = (id: UserId, phone: string) => {
    setUserId(id);
    setActiveStage("validatePhone");
    setPhoneNumber(`7${phone}`);
  };

  const onValidatePhoneSuccess = () => {
    setActiveStage("setPassword");
  };

  const onSetPasswordSuccess = (userDetails: UserAuthDetails) => {
    setUserAuth(userDetails);
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Button
        startIcon={<ArrowBackIcon />}
        size="small"
        component={RouterLink}
        to={selectAppUrl((x) => x.user.account.login).url}
        sx={{ mt: 1 }}
      >
        К форме входа
      </Button>
      <Box justifyContent="center" alignItems="center" display="flex" mb={2}>
        {userRegistrationStageType.values.map((stage, index, values) => {
          return (
            <React.Fragment key={stage}>
              <Typography variant="caption" component="span" fontWeight={stage === activeStage ? "700" : "normal"}>
                {userRegistrationStageType.getTitle(stage)}
              </Typography>
              {index < values.length - 1 && bull}
            </React.Fragment>
          );
        })}
      </Box>
      {activeStage === "setUserData" && <UserRegistrationAddUserForm onSuccess={onAddUserSuccess} />}
      {userId && phoneNumber && activeStage === "validatePhone" && (
        <UserRegistrationVerificationCodeForm
          userId={userId}
          onSuccess={onValidatePhoneSuccess}
          phoneNumber={phoneNumber}
        />
      )}
      {userId && activeStage === "setPassword" && (
        <UserRegistrationAddPasswordForm userId={userId} onSuccess={onSetPasswordSuccess} />
      )}
    </Stack>
  );
};

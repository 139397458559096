import { EnumLikeStringLiteralType, makeEnumLikeStringLiteral } from "common/types";

const titles = {
  Active: "Активные",
  Completed: "Завершенные",
  All: "Все",
};

export const demandListTab = makeEnumLikeStringLiteral(["Active", "Completed", "All"] as const, titles);
export type DemandListTabType = EnumLikeStringLiteralType<typeof demandListTab>;

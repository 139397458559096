import React from "react";
import { Alert, Box, Button, Divider, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useFormik } from "formik";
import {
  ProductLeasingCreateFormValues,
  productLeasingCreateFormValuesKeys,
  productLeasingCreateFormValuesValidationSchema,
} from "product-leasing/create/types/ProductLeasingCreateFormValues";
import { usePostProductLeasingCreateMutation } from "product-leasing/create/api/dto/productLeasingCreateApi";
import { CreateProductLeasingRequest } from "product-leasing/create/api/dto/request/CreateProductLeasingRequest";
import { QueryError } from "common/api/types/QueryError";
import { formFieldsApiValidationService } from "common/services/form";
import { useAppNavigation } from "common/navigation/hooks/useAppNavigation";
import { PriceTextField } from "common/ui/inputs/text-fields/PriceTextField";
import { ProductLeasingCreateUploadDocumentCard } from "product-leasing/create/components/ProductLeasingCreateUploadDocumentCard";
import { useAppSelector } from "common/store";
import { selectProductLeasingCreateUploadedFileIds } from "product-leasing/create/store/productLeasingCreateFiles.selectors";

export const ProductLeasingCreateForm = () => {
  const navigator = useAppNavigation();
  const [create, options] = usePostProductLeasingCreateMutation();
  const uploadedFileIds = useAppSelector(selectProductLeasingCreateUploadedFileIds);

  const form = useFormik<ProductLeasingCreateFormValues>({
    initialValues: productLeasingCreateFormValuesValidationSchema.getDefault() as ProductLeasingCreateFormValues,
    validationSchema: productLeasingCreateFormValuesValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const formValues = productLeasingCreateFormValuesValidationSchema.cast(values, {
          assert: false,
        }) as ProductLeasingCreateFormValues;

        const request: CreateProductLeasingRequest = {
          ...formValues,
          fileIds: uploadedFileIds,
        };

        const response = await create(request).unwrap();
        navigator((x) => x.demand.details, { demandId: response.demandId });
      } catch (err) {
        formFieldsApiValidationService.setFieldErrors(
          (err as QueryError).data,
          productLeasingCreateFormValuesKeys,
          helpers.setFieldError
        );
      }
    },
  });

  return (
    <Stack spacing={2} component="form" onSubmit={form.handleSubmit} noValidate>
      <Box>
        <Typography variant="h3">Новая заявка</Typography>
        <Divider />
      </Box>
      <NumberFormat
        customInput={TextField}
        isAllowed={(values: NumberFormatValues) => values.formattedValue.length <= 12}
        margin="normal"
        required
        fullWidth
        variant="outlined"
        label="ИНН"
        name="clientTaxpayerNumber"
        autoComplete="off"
        value={form.values.clientTaxpayerNumber}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.clientTaxpayerNumber && Boolean(form.errors.clientTaxpayerNumber)}
        helperText={form.touched.clientTaxpayerNumber && form.errors.clientTaxpayerNumber}
        disabled={form.isSubmitting}
        inputProps={{ inputMode: "numeric" }}
      />
      <PriceTextField
        label="Сумма"
        name="leasingSum"
        value={form.values.leasingSum}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.leasingSum && Boolean(form.errors.leasingSum)}
        helperText={form.touched.leasingSum && form.errors.leasingSum}
        disabled={form.isSubmitting}
        required
      />
      <TextField
        margin="normal"
        required
        fullWidth
        variant="outlined"
        label="Предмет"
        multiline
        rows={3}
        name="leasingSubject"
        autoComplete="off"
        value={form.values.leasingSubject}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.leasingSubject && Boolean(form.errors.leasingSubject)}
        helperText={form.touched.leasingSubject && form.errors.leasingSubject}
        disabled={form.isSubmitting}
      />
      <NumberFormat
        customInput={TextField}
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={false}
        margin="normal"
        required
        fullWidth
        variant="outlined"
        label="Срок (месяцев)"
        name="leasingPeriod"
        autoComplete="off"
        type="text"
        value={form.values.leasingPeriod}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        onValueChange={(values) => {
          if (form.values.leasingPeriod !== values.floatValue) {
            form.setFieldValue("leasingPeriod", values.floatValue, true);
          }
        }}
        error={form.touched.leasingPeriod && Boolean(form.errors.leasingPeriod)}
        helperText={form.touched.leasingPeriod && form.errors.leasingPeriod}
        disabled={form.isSubmitting}
        inputProps={{ inputMode: "numeric" }}
      />
      <PriceTextField
        label="Размер аванса"
        name="leasingPrepaidSum"
        value={form.values.leasingPrepaidSum}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.leasingPrepaidSum && Boolean(form.errors.leasingPrepaidSum)}
        helperText={form.touched.leasingPrepaidSum && form.errors.leasingPrepaidSum}
        disabled={form.isSubmitting}
      />
      <TextField
        label="Комментарий"
        name="commentText"
        fullWidth
        variant="outlined"
        multiline
        rows={3}
        value={form.values.commentText}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        disabled={form.isSubmitting}
      />

      <ProductLeasingCreateUploadDocumentCard disabled={form.isSubmitting} />

      {form.isSubmitting && <LinearProgress color="inherit" />}

      {(options.error as QueryError)?.data?.message && (
        <Alert severity="error">{(options.error as QueryError).data.message}</Alert>
      )}

      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        <Button type="submit" variant="contained" color="success">
          Отправить заявку
        </Button>
      </Box>
    </Stack>
  );
};

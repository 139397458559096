/**
 * Возвращает цену со знаком рубля (134815.91 -> 134 815,91 ₽)
 * или замещающую строку, в случае, если сумма не указана
 * @param value Цена/сумма
 * @param placeholder Строка, замещающая
 * @example 134 815,91 ₽
 */
const getCurrencyStringNullable = <TPlaceholder = string | undefined>(
    value: number | undefined | null,
    placeholder: TPlaceholder
): string | TPlaceholder => {
    return (
        value?.toLocaleString("ru-RU", {
            style: "currency",
            currency: "RUB",
        }) ?? placeholder
    );
};

/**
 * Возвращает цену со знаком рубля 134815.91 -> 134 815,91 ₽
 * @param value Цена/сумма
 * @example 134 815,91 ₽
 */
const getCurrencyString = (value: number): string => getCurrencyStringNullable(value, "");

export const currencyHelper = {
    getCurrencyStringNullable,
    getCurrencyString,
};

import { PaletteColor, Theme } from "@mui/material";
import { DropzoneState } from "react-dropzone";

type DropzoneStateProps = Pick<DropzoneState, "isDragActive" | "isDragReject" | "isDragAccept">;

export const getDropzonePallette = (
  theme: Theme,
  { isDragActive, isDragReject, isDragAccept }: DropzoneStateProps
): PaletteColor | undefined => {
  if (isDragActive) {
    return theme.palette.primary;
  }

  if (isDragReject) {
    return theme.palette.error;
  }

  if (isDragAccept) {
    return theme.palette.success;
  }

  return undefined;
};

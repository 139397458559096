import { combineSliceNames } from "common/store/utils";
import { createSlice } from "@reduxjs/toolkit";
import { queueTasksListAdapter } from "queue-tasks/list/store/queueTasksList.adapter";
import { QueueTasksListSliceState } from "queue-tasks/list/store/queueTasksList.types";
import { QUEUE_TASKS_SLICE_NAME } from "queue-tasks/common/store";
import { queueTasksListApi } from "queue-tasks/list/api/queueTasksListApi";

const QUEUE_TASKS_LIST_SLICE_NAME = combineSliceNames(QUEUE_TASKS_SLICE_NAME, "list");

const initialState = queueTasksListAdapter.getInitialState<QueueTasksListSliceState>({
  pagination: undefined,
});

const slice = createSlice({
  initialState,
  name: QUEUE_TASKS_LIST_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(queueTasksListApi.endpoints.getQueueTasksListConveyor.matchPending, (state, action) => {
        if (
          state.pagination &&
          action.meta.arg.originalArgs.pageNumber &&
          action.meta.arg.originalArgs.pageNumber <= state.pagination.pageNumber
        ) {
          queueTasksListAdapter.removeAll(state);
          state.pagination = undefined;
        }
      })
      .addMatcher(queueTasksListApi.endpoints.getQueueTasksListConveyor.matchFulfilled, (state, action) => {
        if (action.payload.pagination?.pageNumber === 1) {
          queueTasksListAdapter.setAll(state, action.payload.queueTasks);
        } else {
          queueTasksListAdapter.upsertMany(state, action.payload.queueTasks);
        }

        state.pagination = action.payload.pagination;
      });
  },
});

export const queueTasksListReducer = slice.reducer;

import { appApi } from "common/api";
import { GetQueueTasksListResponse } from "queue-tasks/list/api/dto/response/GetQueueTasksListResponse";
import { GetQueueTasksListRequest } from "queue-tasks/list/api/dto/request/GetQueueTasksListRequest";

export const queueTasksListApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getQueueTasksListConveyor: build.query<GetQueueTasksListResponse, GetQueueTasksListRequest>({
      query: (queryArg) => ({
        url: `/api/v1/conveyor/queue-tasks`,
        params: queryArg,
      }),
    }),
  }),
});

export const { useGetQueueTasksListConveyorQuery } = queueTasksListApi;

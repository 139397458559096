import { useEffect, useRef, useState } from "react";

const VALIDATION_TIMER_SECONDS = 90;

export const useVerificationCodeCountdown = (canSendAgain: boolean, onTimerExpires: () => void) => {
  const [secondsCountdown, setSecondsCountdown] = useState<number | undefined>(VALIDATION_TIMER_SECONDS);

  const onTimerExpiresRef = useRef(onTimerExpires);

  useEffect(() => {
    if (!canSendAgain && secondsCountdown === undefined) {
      setSecondsCountdown(VALIDATION_TIMER_SECONDS);
    }
  }, [canSendAgain, secondsCountdown]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!canSendAgain && secondsCountdown !== undefined) {
      interval = setInterval(() => {
        if (secondsCountdown > 0) {
          setSecondsCountdown(secondsCountdown - 1);
        }
        if (secondsCountdown === 0) {
          clearInterval(interval);
          onTimerExpiresRef.current();
          setSecondsCountdown(undefined);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [canSendAgain, secondsCountdown]);

  return secondsCountdown;
};

import React from "react";
import { Link, Typography } from "@mui/material";

export const Copyright = ({ sx }: Pick<React.ComponentProps<typeof Typography>, "sx">) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={sx}>
      © ООО
      <Link color="inherit" target="_blank" referrerPolicy="no-referrer" href="/">
        «Теледок»
      </Link>
      &nbsp;Все права защищены 2012 — {new Date().getFullYear()} г.
    </Typography>
  );
};

import React, { useMemo } from "react";
import { DemandListHeader } from "demand/list/components/DemandListHeader";
import { DemandListItemsContainer } from "demand/list/components/DemandListItemsContainer";
import { Stack } from "@mui/material";
import { useGetQueryParams } from "common/filters";
import { demandListFiltersValidationSchema } from "demand/list/types/DemandListFilterParams";
import { useDemandListFilterMutator } from "demand/list/hooks/useDemandListFilterMutator";
import { useAppSelector } from "common/store";
import { selectUserAuth } from "user/auth";
import { useGetDemandListConsumerQuery, useGetDemandListConveyorQuery } from "demand/list/api/demandListApi";

export const DemandListPage = () => {
  const [filteringObject] = useGetQueryParams(demandListFiltersValidationSchema);
  const mutator = useDemandListFilterMutator();
  const { user } = useAppSelector(selectUserAuth);

  const fetcher = useMemo(
    () => (user?.isConsumer ? useGetDemandListConsumerQuery : useGetDemandListConveyorQuery),
    [user?.isConsumer]
  );

  return (
    <Stack spacing={4}>
      {filteringObject.pageNumber >= 1 && (
        <>
          <DemandListHeader
            filteringObject={filteringObject}
            mutator={mutator}
            canCreateNewDemand={user?.isConsumer === true}
          />
          <DemandListItemsContainer filteringObject={filteringObject} mutator={mutator} fetcher={fetcher} />
        </>
      )}
    </Stack>
  );
};

import { demandListAdapter } from "demand/list/store/demandList.adapter";
import { combineSliceNames } from "common/store/utils";
import { DEMAND_SLICE_NAME } from "demand/common/store";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { demandListApi } from "demand/list/api/demandListApi";
import { DemandListSliceState } from "demand/list/store/demandList.types";

const DEMAND_LIST_SLICE_NAME = combineSliceNames(DEMAND_SLICE_NAME, "list");

const initialState = demandListAdapter.getInitialState<DemandListSliceState>({
  pagination: undefined,
});

const slice = createSlice({
  initialState,
  name: DEMAND_LIST_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          demandListApi.endpoints.getDemandListConsumer.matchPending,
          demandListApi.endpoints.getDemandListConveyor.matchPending
        ),
        (state, action) => {
          if (
            state.pagination &&
            action.meta.arg.originalArgs.pageNumber &&
            action.meta.arg.originalArgs.pageNumber <= state.pagination.pageNumber
          ) {
            demandListAdapter.removeAll(state);
            state.pagination = undefined;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          demandListApi.endpoints.getDemandListConsumer.matchFulfilled,
          demandListApi.endpoints.getDemandListConveyor.matchFulfilled
        ),
        (state, action) => {
          if (action.payload.pagination?.pageNumber === 1) {
            demandListAdapter.setAll(state, action.payload.demands);
          } else {
            demandListAdapter.upsertMany(state, action.payload.demands);
          }

          state.pagination = action.payload.pagination;
        }
      );
  },
});

export const demandListReducer = slice.reducer;

import React from "react";
import { QueueTaskId } from "common/types";
import { useAppSelector } from "common/store";
import { selectQueueTasksListItemById } from "queue-tasks/list/store/queueTasksList.selectors";
import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { dateTimeHelper, specialFormatters } from "common/helpers/date";
import { Link } from "react-router-dom";
import { generatePath } from "common/navigation/utils/generatePath";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { QueueTasksListItemDl } from "queue-tasks/list/components/QueueTasksListItemDl";
import { currencyHelper } from "common/helpers/number";
import { NavigationState } from "common/navigation/navigation-state";

export interface QueueTasksListItemProps {
  queueTaskId: QueueTaskId;
  from?: NavigationState;
}

export const QueueTasksListItem = ({ queueTaskId, from }: QueueTasksListItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const queueTask = useAppSelector((state) => selectQueueTasksListItemById(state, queueTaskId))!;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary" gutterBottom>
          Задача {queueTask.queueTaskNumber ?? queueTask.queueTaskId} по заявке{" "}
          {queueTask.demandNumber ?? queueTask.demandId}
        </Typography>
        <Typography variant="h6" component="div">
          {queueTask.clientName}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {queueTask.clientTaxpayerNumber}
        </Typography>
        {queueTask.demandSum && (
          <QueueTasksListItemDl title="Сумма:" value={currencyHelper.getCurrencyString(queueTask.demandSum)} />
        )}
        <QueueTasksListItemDl
          title="Задача от:"
          value={`${queueTask.userCreatorName} (${queueTask.userCreatorRoleTitle})`}
        />
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <QueueTasksListItemDl
            title="создано:"
            value={dateTimeHelper.format(
              new Date(queueTask.dateCreated),
              specialFormatters.dayFullMonthYearIfDifferentAndTime
            )}
          />
          <QueueTasksListItemDl
            title="принято:"
            value={
              queueTask.dateStart
                ? dateTimeHelper.format(
                    new Date(queueTask.dateStart),
                    specialFormatters.dayFullMonthYearIfDifferentAndTime
                  )
                : "-"
            }
          />
          <QueueTasksListItemDl
            title="закрыто:"
            value={
              queueTask.dateFinal
                ? dateTimeHelper.format(
                    new Date(queueTask.dateFinal),
                    specialFormatters.dayFullMonthYearIfDifferentAndTime
                  )
                : "-"
            }
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={generatePath((x) => x.queueTasks.details, { taskId: queueTaskId })}
          state={from}
          endIcon={<ArrowRightAltIcon />}
        >
          Перейти
        </Button>
      </CardActions>
    </Card>
  );
};

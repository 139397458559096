import React, { ChangeEvent, useEffect, useState } from "react";
import { Alert, Box, Button, CardActions, LinearProgress, Stack, TextField } from "@mui/material";
import { FileCard } from "files/download";
import { QueryError } from "common/api/types/QueryError";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { getDropzonePallette } from "common/ui/theme";

export interface DemandCommentProps<T> {
  options: T;
  handleSubmit: (text: string, files: File[]) => void;
}

export const DemandComment = <T extends { isLoading: boolean; isError: boolean; isSuccess: boolean; error?: unknown }>({
  options,
  handleSubmit,
}: DemandCommentProps<T>) => {
  const [commentValue, setCommentValue] = useState<string>("");
  const [filesArr, setFilesArr] = useState<File[]>([]);
  const { acceptedFiles, getRootProps, open, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    noClick: true,
  });

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCommentValue(e.target.value);
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      setFilesArr([...filesArr, ...acceptedFiles]);
    }
    return () => {
      acceptedFiles.length = 0;
    };
  }, [acceptedFiles, filesArr]);

  useEffect(() => {
    if (options.isSuccess) {
      setCommentValue("");
      setFilesArr([]);
    }
  }, [options.isSuccess]);

  const sendCommentHandler = () => {
    handleSubmit(commentValue, filesArr);
  };

  return (
    <Stack
      sx={(theme) => ({
        borderWidth: isDragActive ? "1px" : "0px",
        borderStyle: "solid",
        borderColor: getDropzonePallette(theme, { isDragActive, isDragReject, isDragAccept })?.light,
      })}
      {...getRootProps()}
    >
      <TextField
        margin="normal"
        style={{
          marginTop: 0,
        }}
        fullWidth
        variant="outlined"
        placeholder="Напишите комментарий"
        multiline
        rows={3}
        name="comment"
        autoComplete="off"
        value={commentValue}
        onChange={onInputChange}
      />
      {options.isLoading && <LinearProgress color="inherit" />}
      {options.isError && options.error && (
        <Alert severity="error">
          {(options.error as QueryError).data?.message ?? "Не удалось установить соединение с сервером"}
        </Alert>
      )}
      <Stack spacing={2} direction="column" alignItems="start">
        {filesArr.map((file: File) => (
          <FileCard
            key={file.lastModified}
            name={file.name}
            downloadLink={file.webkitRelativePath}
            onDelete={() => setFilesArr(filesArr.filter((item) => item.lastModified !== file.lastModified))}
          />
        ))}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { md: "column", xs: "column", lg: "row", xl: "row" },
        }}
      >
        <input {...getInputProps()} />
        <CardActions>
          <Button size="small" startIcon={<CloudUploadIcon />} onClick={open}>
            Прикрепить файлы
          </Button>
        </CardActions>
        <Button
          type="submit"
          variant="contained"
          color="success"
          onClick={sendCommentHandler}
          disabled={options.isLoading || (!commentValue && !filesArr.length)}
        >
          Отправить комментарий
        </Button>
      </Box>
    </Stack>
  );
};

import React from "react";
import { useLocationPathEffect } from "common/hooks";
import { useAppSelector } from "common/store";
import { selectServiceWorker } from "application/service-worker/store/serviceWorker.selectors";
import { useServiceWorkerUpdater } from "application/service-worker/hooks/useServiceWorkerUpdater";

export interface ServiceWorkerProviderProps {
  children: React.ReactNode;
}

export const ServiceWorkerProvider = ({ children }: ServiceWorkerProviderProps): JSX.Element => {
  const { isUpdated, registration } = useAppSelector(selectServiceWorker);
  const updateServiceWorker = useServiceWorkerUpdater();

  useLocationPathEffect(() => {
    if (isUpdated && registration) {
      updateServiceWorker(registration);
    }
  }, [isUpdated, registration]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

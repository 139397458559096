import { useMemo } from "react";
import { NavigationState } from "../types/NavigationState";
import { useNavigationStateFrom } from "./useNavigationStateFrom";

export const useNavigationStateFromWithFallback = (fallbackUrl: string, fallbackTitle?: string): NavigationState => {
  const state = useNavigationStateFrom();
  return useMemo(
    () => (state?.from ? state : { from: { url: fallbackUrl, backTitle: fallbackTitle } }),
    [state, fallbackUrl, fallbackTitle]
  );
};

/* eslint-disable no-template-curly-in-string */
import { InferType, number, object, string } from "yup";
import { getSchemaKeys, getTaxpayerNumberSchema } from "common/validation";
import { getFormattedNumberTransformer } from "common/validation/transformers/getFormattedNumberTransformer";

export const productLeasingCreateFormValuesValidationSchema = object({
  clientTaxpayerNumber: getTaxpayerNumberSchema().default("").required("Укажите ИНН"),
  leasingSum: number()
    .transform(getFormattedNumberTransformer)
    .min(0, "Укажите сумму больше ноля")
    .required("Укажите сумму"),
  leasingSubject: string().default("").required("Укажите предмет лизинга"),
  leasingPeriod: number()
    .min(1, "Минимальный срок - один месяц")
    .max(240, "Максимальный срок - ${max} месяцев")
    .required("Укажите срок лизинга"),
  leasingPrepaidSum: number()
    .nullable(true)
    .notRequired()
    .transform(getFormattedNumberTransformer)
    .min(0, "Укажите сумму больше ноля"),
  commentText: string().default(""),
});

export const productLeasingCreateFormValuesKeys = getSchemaKeys(productLeasingCreateFormValuesValidationSchema);

export type ProductLeasingCreateFormValues = InferType<typeof productLeasingCreateFormValuesValidationSchema>;

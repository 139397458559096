import React from "react";
import { useParams } from "react-router-dom";
import { DemandId } from "common/types";
import { DemandConveyorDetails } from "demand/conveyor-details/components/DemandConveyorDetails";
import { ErrorAlert } from "common/ui/alerts";

export const DemandConveyorDetailsPage = () => {
  const { demandId } = useParams<{ demandId: DemandId }>();

  if (!demandId) {
    return <ErrorAlert error="Заявка не найдена" />;
  }

  return <DemandConveyorDetails demandId={demandId} />;
};

import { appApi } from "common/api";
import { FilesUploadConsumerResponse } from "files/upload/api/dto/response/FilesUploadConsumerResponse";
import { FilesUploadConsumerRequest } from "files/upload/api/dto/request/FilesUploadConsumerRequest";

export const filesUploadApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    uploadFilesConsumer: build.mutation<FilesUploadConsumerResponse, FilesUploadConsumerRequest>({
      query: (queryArg) => {
        const formData = new FormData();
        Array.from(queryArg.files).forEach((x) => {
          formData.append("files", x);
        });

        return { url: `api/v1/consumer/files/upload`, method: "PUT", body: formData };
      },
    }),
  }),
});

export const { useUploadFilesConsumerMutation } = filesUploadApi;

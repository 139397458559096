import { store } from "common/store";
import { serviceWorkerInitialised, serviceWorkerUpdated } from "application/service-worker/store/serviceWorker.store";
import * as registration from "application/service-worker/services/serviceWorkerRegistration";

const register = () => {
  registration.register({
    onSuccess: () => {
      store.dispatch(serviceWorkerInitialised());
    },
    onUpdate: (reg) => {
      store.dispatch(serviceWorkerUpdated(reg));
    },
  });
};

export const serviceWorkerRegistrationService = {
  register,
};

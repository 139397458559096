import { combineSliceNames } from "common/store/utils";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { DEMAND_SLICE_NAME } from "demand/common/store";
import { CommentsSliceState } from "./demandComment.types";
import { demandCommentsAdapter } from "./demandCommentsAdapter.adapter";
import { demandConsumerCommentsApi } from "../consumer/api/demandConsumerCommentsApi";
import { demandConveyorCommentsApi } from "../conveyor/api/demandConveyorCommentsApi";

const initialState = demandCommentsAdapter.getInitialState<CommentsSliceState>({
  pagination: undefined,
});
const DEMAND_COMMENTS_SLICE_NAME = combineSliceNames(DEMAND_SLICE_NAME, "comments");

const slice = createSlice({
  name: DEMAND_COMMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          demandConsumerCommentsApi.endpoints.getDemandConsumerComments.matchPending,
          demandConveyorCommentsApi.endpoints.getDemandConveyorComments.matchPending
        ),
        (state, action) => {
          if (
            state.pagination &&
            action.meta.arg.originalArgs.pageNumber &&
            action.meta.arg.originalArgs.pageNumber <= state.pagination.pageNumber
          ) {
            demandCommentsAdapter.removeAll(state);
            state.pagination = undefined;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          demandConsumerCommentsApi.endpoints.getDemandConsumerComments.matchFulfilled,
          demandConveyorCommentsApi.endpoints.getDemandConveyorComments.matchFulfilled
        ),
        (state, action) => {
          if (action.payload.pagination?.pageNumber === 1) {
            demandCommentsAdapter.setAll(state, action.payload.comments);
          } else {
            demandCommentsAdapter.upsertMany(state, action.payload.comments);
          }

          state.pagination = action.payload.pagination;
        }
      )
      .addMatcher(
        isAnyOf(
          demandConsumerCommentsApi.endpoints.postDemandConsumerCreateComment.matchFulfilled,
          demandConveyorCommentsApi.endpoints.postDemandConveyorCreateComment.matchFulfilled
        ),
        (state, action) => {
          demandCommentsAdapter.upsertMany(state, action.payload.comments);
        }
      );
  },
});

export const commentsReducer = slice.reducer;

export enum AppUrlTypeEnum {
  /**
   * Путь доступен только авторизованным пользователям
   */
  Private,
  /**
   * Путь доступен только не авторизованным пользователям
   */
  Public,
  /**
   * Общий путь - доступен и авторизованным, и не авторизованным пользователям
   */
  Common,
}

import { useMemo } from "react";
import { Pagination } from "common/filters";

export const useAppPagination = (
  isLoading: boolean,
  isFailed: boolean,
  isSucceeded: boolean,
  items: unknown[],
  pagination: Pagination | undefined
) => {
  return useMemo(
    () => ({
      hasMore: !isLoading && !isFailed && (!pagination || pagination.pageNumber < pagination.pageCount),
      isEmptyList: items.length === 0,
      hasNoLoadedItems: isSucceeded && items.length === 0,
      isEmptyLoading: isLoading && items.length === 0,
    }),
    [isFailed, isLoading, isSucceeded, items.length, pagination]
  );
};

import React from "react";
import { QueueTaskId } from "common/types";
import { Alert, AlertTitle, LinearProgress, Stack } from "@mui/material";
import { DemandDetailsView } from "demand/details";
import { QueryError } from "common/api/types/QueryError";
import { QueueTasksDetailsDecisionsGrid } from "queue-tasks/details/components/QueueTasksDetailsDecisionsGrid";
import {
  useGetQueueTasksConveyorDetailsQuery,
  useSendQueueTasksConveyorDetailsDecisionMutation,
} from "queue-tasks/conveyor-details/api/queueTasksConveyorDetailsApi";
import Box from "@mui/material/Box";
import { BackStateLinkButton } from "common/ui/buttons";
import { selectAppUrl } from "common/urls";
import { DemandConveyorCommentWrapper } from "demand/comments";

export interface QueueTasksConveyorDetailsProps {
  queueTaskId: QueueTaskId;
}

export const QueueTasksConveyorDetails = ({ queueTaskId }: QueueTasksConveyorDetailsProps) => {
  const { data, isLoading, isError, error } = useGetQueueTasksConveyorDetailsQuery({ queueTaskId });

  const [send, sendOptions] = useSendQueueTasksConveyorDetailsDecisionMutation();

  const onSendDecisionClick = (decision: string) => {
    send({ result: decision, queueTaskId });
  };

  return (
    <Stack spacing={2}>
      <Box alignSelf="self-start">
        <BackStateLinkButton fallbackLink={selectAppUrl((x) => x.queueTasks.index).url} fallbackTitle="Мои задачи" />
      </Box>
      {isLoading && <LinearProgress />}
      {isError && (
        <Alert severity="error">
          <AlertTitle>Не удалось получить данные по задаче</AlertTitle>
          {(error as QueryError)?.data?.message ?? "Не удалось установить соединение с сервером"}
        </Alert>
      )}
      {data?.demand && <DemandDetailsView details={data.demand} showUser />}
      {data?.resultTypes && (
        <>
          <QueueTasksDetailsDecisionsGrid
            title="Действия по задаче"
            success={sendOptions.isSuccess}
            successMessage="Решение по задаче отправлено"
            error={sendOptions.isError}
            queryError={sendOptions.error as QueryError}
            errorFallbackMessage="Не удалось установить соединение с сервером"
            errorTitle="Задача не отправлена"
            loading={sendOptions.isLoading}
            resultTypes={data.resultTypes}
            onSendDecisionClick={onSendDecisionClick}
            sentDecision={sendOptions.originalArgs?.result}
          />
          <DemandConveyorCommentWrapper
            demandId={data.demand.demandId}
            queueTaskId={queueTaskId}
            isPossibleToAddComment
          />
        </>
      )}
    </Stack>
  );
};

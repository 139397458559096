import { InferType, number, object, string } from "yup";
import { getEnumLikeStringLiteralTypeSchema } from "common/validation";
import { demandListTab } from "demand/list/types/DemandListTabType";

export const demandListFiltersValidationSchema = object({
  searchByDemand: string().default(""),
  searchByClient: string().default(""),
  tab: getEnumLikeStringLiteralTypeSchema(demandListTab).default("Active"),
  pageNumber: number().default(1),
});

export type DemandListFilterParams = InferType<typeof demandListFiltersValidationSchema>;

import React from "react";
import { DemandDetails } from "demand/details/types/DemandDetails";
import { Box, Button, CardActions, Divider, Grid, Stack, Typography } from "@mui/material";
import { dateTimeHelper, specialFormatters } from "common/helpers/date";
import { DemandDetailsItem } from "demand/details/components/DemandDetailsItem";
import { entityHelper } from "common/helpers/entity";
import { currencyHelper } from "common/helpers/number";
import { DemandStatusTitle } from "demand/common/components/DemandStatusTitle";
import { FileCard, useFileDownloadLink } from "files/download";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { generatePath } from "common/navigation/utils/generatePath";
import { Link } from "react-router-dom";

export interface DemandDetailsViewProps {
  details: DemandDetails;
  showUser: boolean;
  showQueueTaskLink?: boolean;
}

export const DemandDetailsView = ({ details, showUser, showQueueTaskLink }: DemandDetailsViewProps) => {
  const getDownloadFileLink = useFileDownloadLink();

  return (
    <Stack spacing={2}>
      <Stack spacing={0.5}>
        <Typography variant="h5" component="h2">
          Заявка {details.demandNumber ?? details.demandId}
        </Typography>
        <Stack spacing={2} direction="row" alignItems="baseline">
          <Box>
            <Typography variant="caption">
              от&nbsp;
              {dateTimeHelper.format(
                new Date(details.dateCreated),
                specialFormatters.dayFullMonthYearIfDifferentAndTime
              )}
            </Typography>
            <DemandStatusTitle
              variant="caption"
              isFailure={details.isFailure}
              isApproved={details.isApproved}
              isSuccess={details.isSuccess}
            />
          </Box>
          {showQueueTaskLink && details.activeQueueTaskId && (
            <CardActions sx={{ padding: 0 }}>
              <Button
                size="small"
                component={Link}
                to={generatePath((x) => x.queueTasks.details, { taskId: details.activeQueueTaskId })}
                endIcon={<ArrowRightAltIcon />}
              >
                Перейти к задаче
              </Button>
            </CardActions>
          )}
        </Stack>
        <Divider />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <DemandDetailsItem title="Клиент" description={details.clientName} />
            <DemandDetailsItem title="ИНН клиента" description={details.clientTaxpayerNumber} />
            {showUser && (
              <>
                <DemandDetailsItem title="Пользователь" description={details.userName} />
                {details.userPhoneNumber && (
                  <DemandDetailsItem
                    title="Телефон пользователя"
                    description={entityHelper.formatPhoneNumber(details.userPhoneNumber)}
                  />
                )}
              </>
            )}
          </Stack>
        </Grid>
        {details.leasing && (
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <DemandDetailsItem title="Срок (месяцев)" description={details.leasing.periodInMonths} />
              <DemandDetailsItem title="Сумма" description={currencyHelper.getCurrencyString(details.leasing.sum)} />
              <DemandDetailsItem
                title="Размер аванса"
                description={currencyHelper.getCurrencyStringNullable(details.leasing.prepaidSum, "Нет")}
              />
              <DemandDetailsItem title="Предмет лизинга" description={details.leasing.subject} />
            </Stack>
          </Grid>
        )}
      </Grid>
      {details.documentFiles && details.documentFiles.length > 0 && (
        <>
          <Box>
            <Typography variant="h5" component="h2">
              Документы по заявке
            </Typography>
            <Divider />
          </Box>
          <Stack spacing={1} alignItems="start">
            {details.documentFiles.map((x) => (
              <FileCard
                key={x.fileId}
                name={x.fileName}
                downloadLink={getDownloadFileLink(x)}
                dateCreated={new Date(x.dateCreated)}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

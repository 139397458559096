import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { removeUserAuth } from "user/auth/store/userAuth.slice";
import { userAuthLocalStorage } from "user/auth/store/userAuth.localStorage";

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      userAuthLocalStorage.removeUser();
      dispatch(removeUserAuth());
    }

    return next(action);
  };

import React from "react";
import { useAppDispatch, useAppSelector } from "common/store";
import { selectProductLeasingCreateFileById } from "product-leasing/create/store/productLeasingCreateFiles.selectors";
import { productLeasingCreateFileRemove } from "product-leasing/create/store/productLeasingCreateFiles.slice";
import { FileCard } from "files/download";

export interface ProductLeasingCreationUploadFileProps {
  id: string;
}

export const ProductLeasingCreationUploadFile = ({ id }: ProductLeasingCreationUploadFileProps) => {
  const dispatch = useAppDispatch();
  const file = useAppSelector((state) => selectProductLeasingCreateFileById(state, id));

  if (!file) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  if (file.isUploaded) {
    return (
      <FileCard
        name={file.name}
        downloadLink={file.downloadLink}
        onDelete={() => {
          dispatch(productLeasingCreateFileRemove({ id }));
        }}
      />
    );
  }

  return <FileCard name={file.name} status={file.status} />;
};

import React from "react";
import { Typography } from "@mui/material";

export interface DemandStatusTitleProps {
  isApproved: boolean;
  isSuccess: boolean;
  isFailure: boolean;
}

const getTextByProps = ({ isSuccess, isFailure, isApproved }: DemandStatusTitleProps) => {
  if (isSuccess) {
    return "Выпущена";
  }

  if (isFailure) {
    return "Завершена";
  }

  if (isApproved) {
    return "Одобрена";
  }

  return "";
};

const getColorByProps = ({ isSuccess, isFailure, isApproved }: DemandStatusTitleProps) => {
  if (isSuccess) {
    return "success.light";
  }

  if (isFailure) {
    return "error.light";
  }

  if (isApproved) {
    return "info.light";
  }

  return "";
};

export const DemandStatusTitle = ({
  isSuccess,
  isFailure,
  isApproved,
  ...rest
}: DemandStatusTitleProps & Omit<React.ComponentProps<typeof Typography>, "color">) => {
  if (!isApproved && !isFailure && !isSuccess) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  return (
    <Typography color={getColorByProps({ isSuccess, isFailure, isApproved })} {...rest}>
      {getTextByProps({ isSuccess, isFailure, isApproved })}
    </Typography>
  );
};

import React from "react";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "common/ui/theme";
import { AppRouter } from "common/routes";

export const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <AppRouter />
    </ThemeProvider>
  );
};

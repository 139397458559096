import { useCallback, useEffect, useRef, useState } from "react";

export interface UseInfiniteScrollOptions {
  initialPage?: number;
  rootMargin?: number;
}

export const useInfiniteScroll = (hasMore: boolean, loading: boolean, options?: UseInfiniteScrollOptions) => {
  const [pageNumber, setPageNumber] = useState(options?.initialPage ?? 1);
  const [rootTopMargin] = useState(options?.rootMargin ?? 500);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    setPageNumber(options?.initialPage ?? 1);
  }, [options?.initialPage]);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        },
        { rootMargin: `0px 0px ${rootTopMargin}px 0px`, threshold: 0 }
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore, rootTopMargin]
  );

  return [pageNumber, lastElementRef] as const;
};

import React from "react";
import { Box, Typography } from "@mui/material";

export const QueueTasksListHeader = () => {
  return (
    <Box component="header">
      <Typography variant="h3" component="h2" fontWeight="400">
        Мои задачи
      </Typography>
    </Box>
  );
};

import React from "react";
import { useApplicationUpdater } from "application/updater/hooks/useApplicationUpdater";
import { useLocationPathEffect } from "common/hooks";

export interface ApplicationUpdaterProps {
  children: React.ReactNode;
}

export const ApplicationUpdater = ({ children }: ApplicationUpdaterProps) => {
  const needRefresh = useApplicationUpdater();

  useLocationPathEffect(() => {
    if (needRefresh) {
      window.location.reload();
    }
  }, [needRefresh]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { mapAppRoutes } from "common/routes/utils/mapAppRoutes";
import { appRoutes } from "common/routes/appRoutes";
import { ServiceWorkerProvider } from "application/service-worker";
import { ApplicationUpdater } from "application/updater";

const Routes = () => useRoutes(mapAppRoutes(appRoutes));
export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ApplicationUpdater>
        <ServiceWorkerProvider>
          <Routes />
        </ServiceWorkerProvider>
      </ApplicationUpdater>
    </BrowserRouter>
  );
};

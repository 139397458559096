import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { AnySchema, InferType, ValidationError } from "yup";

const getObjectFromQueryParams = (search: URLSearchParams): Record<string, string | string[]> => {
  return Array.from(search.entries()).reduce((accum, [key, value]) => {
    if (accum[key]) {
      if (Array.isArray(accum[key])) {
        (accum[key] as string[]).push(value);
      } else {
        accum[key] = [accum[key] as string, value];
      }
    } else {
      accum[key] = value;
    }

    return accum;
  }, {} as Record<string, string | string[]>);
};

const castValueWithIgnoreFields = async <TValidationSchema extends AnySchema>(
  schema: TValidationSchema,
  data: Record<string, string | string[]>
) => {
  let queryData = data;
  try {
    await schema.validate(data, { stripUnknown: true, abortEarly: false, strict: false });
  } catch (err: unknown) {
    const validationErrors = err as ValidationError;
    if (validationErrors.inner?.length) {
      const invalidKeys = validationErrors.inner.map((x) => x.path ?? "");
      queryData = Object.fromEntries(Object.entries(data).filter(([key]) => !invalidKeys.includes(key)));
    } else {
      queryData = {};
    }
  }

  return schema.cast(queryData, { stripUnknown: true });
};

const isURLSearchParamsEquals = (a: URLSearchParams, b: URLSearchParams) => {
  const aValues = Array.from(a.values());
  const bValues = Array.from(b.values());

  if (aValues.length !== bValues.length) {
    return false;
  }

  return aValues.every((x) => b.has(x));
};

export const useGetQueryParams = <TValidationSchema extends AnySchema>(
  schema: TValidationSchema
): [filteringObject: InferType<TValidationSchema>, forceUpdate: () => void] => {
  const [emptyValue] = useState({} as TValidationSchema);

  const { search } = useLocation();
  const [queryObject, setQueryObject] = useState<InferType<TValidationSchema> | undefined>(undefined);
  const [prevSearch, setPrevSearch] = useState<string | undefined>();

  const [needUpdate, setNeedUpdate] = useState(false);

  const forceUpdate = useCallback(() => {
    setNeedUpdate(true);
  }, []);

  useEffect(() => {
    if (
      prevSearch !== undefined ||
      (search !== prevSearch && !isURLSearchParamsEquals(new URLSearchParams(prevSearch), new URLSearchParams(search)))
    ) {
      setNeedUpdate(true);
    }

    setPrevSearch(search);
  }, [search, prevSearch]);

  useEffect(() => {
    let isActive = true;
    const transform = async () => {
      const filter = await castValueWithIgnoreFields(schema, getObjectFromQueryParams(new URLSearchParams(search)));
      if (isActive) {
        setQueryObject(filter);
        setNeedUpdate(false);
      }
    };

    if (needUpdate) {
      transform();
    }

    return () => {
      isActive = false;
    };
  }, [needUpdate, schema, search]);

  return [queryObject ?? emptyValue, forceUpdate];
};

import { appApi, EmptyResponse } from "common/api";
import { UserRegistrationAddUserResponse } from "user/registration/api/dto/response/UserRegistrationAddUserResponse";
import { UserRegistrationAddUserRequest } from "user/registration/api/dto/request/UserRegistrationAddUserRequest";
import { UserRegistrationVerifyVerificationCodeResponse } from "user/registration/api/dto/response/UserRegistrationVerifyVerificationCodeResponse";
import { UserRegistrationVerifyVerificationCodeRequest } from "user/registration/api/dto/request/UserRegistrationVerifyVerificationCodeRequest";
import { UserRegistrationAddPasswordResponse } from "user/registration/api/dto/response/UserRegistrationAddPasswordResponse";
import { UserRegistrationAddPasswordRequest } from "user/registration/api/dto/request/UserRegistrationAddPasswordRequest";
import { UserRegistrationSendVerificationCodeRequest } from "user/registration/api/dto/request/UserRegistrationSendVerificationCodeRequest";

const BASE_PATH = "/api/v1/anonymous";
const USER_CONTROLLER_NAME = `${BASE_PATH}/user`;
const AUTH_CONTROLLER_NAME = `${BASE_PATH}/accounts/auth`;

export const userRegistrationApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    postUserRegistrationAddUser: build.mutation<UserRegistrationAddUserResponse, UserRegistrationAddUserRequest>({
      query: (queryArg) => ({
        url: `${USER_CONTROLLER_NAME}/add-user`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postUserRegistrationSendVerificationCode: build.mutation<
      EmptyResponse,
      UserRegistrationSendVerificationCodeRequest
    >({
      query: (queryArg) => ({
        url: `${USER_CONTROLLER_NAME}/send-verification-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postUserRegistrationVerifyVerificationCode: build.mutation<
      UserRegistrationVerifyVerificationCodeResponse,
      UserRegistrationVerifyVerificationCodeRequest
    >({
      query: (queryArg) => ({
        url: `${USER_CONTROLLER_NAME}/verify-verification-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postUserRegistrationAddPassword: build.mutation<
      UserRegistrationAddPasswordResponse,
      UserRegistrationAddPasswordRequest
    >({
      query: (queryArg) => ({
        url: `${AUTH_CONTROLLER_NAME}/add-password`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
});

export const {
  usePostUserRegistrationAddPasswordMutation,
  usePostUserRegistrationAddUserMutation,
  usePostUserRegistrationSendVerificationCodeMutation,
  usePostUserRegistrationVerifyVerificationCodeMutation,
} = userRegistrationApi;

import React from "react";
// eslint-disable-next-line import/no-unresolved
import { AppLayout } from "common/ui/layout/components/AppLayout";
import { AppUrlTypeEnum, selectAppUrl } from "common/urls";
import { UserAuthLogoutPage } from "user/auth/pages/UserAuthLogoutPage";
import { AppLayoutRoute, AppRoute } from "common/routes/types/AppRoute";
import { Navigate } from "react-router-dom";
import { UserAuthLoginPage } from "user/auth/pages/UserAuthLoginPage";
import { ProductLeasingCreatePage } from "product-leasing/create/pages/ProductLeasingCreatePage";
import { DemandListPage } from "demand/list";
import { PublicAuthUserLayout } from "common/ui/layout/components/PublicAuthUserLayout";
import { UserRegistrationPage } from "user/registration";
import { QueueTasksListPage } from "queue-tasks/list";
import { QueueTasksConveyorDetailsPage } from "queue-tasks/conveyor-details";
import { DemandConsumerDetailsPage } from "demand/consumer-details";
import { UserRoleEnum } from "user/common/types";
import { DemandConveyorDetailsPage } from "demand/conveyor-details";
import { makeAppLayoutRoute } from "common/routes/utils/makeAppLayoutRoute";
import { makeAppRoute } from "common/routes/utils/makeAppRoute";
import { ErrorAlert } from "common/ui/alerts";

export const appRoutes: (AppRoute | AppLayoutRoute)[] = [
  makeAppLayoutRoute(AppLayout, [
    makeAppRoute((x) => x.demand.index, <DemandListPage />),
    makeAppRoute((x) => x.demand.details, <DemandConveyorDetailsPage />, [], {
      [UserRoleEnum.Consumer]: <DemandConsumerDetailsPage />,
    }),
    makeAppRoute((x) => x.products.leasing.create, <ProductLeasingCreatePage />),
    makeAppRoute((x) => x.queueTasks.index, <QueueTasksListPage />),
    makeAppRoute((x) => x.queueTasks.details, <QueueTasksConveyorDetailsPage />),
  ]),
  makeAppRoute((x) => x.index, <Navigate to={selectAppUrl((x) => x.demand.index).url} />, [], {
    [UserRoleEnum.Consumer]: <Navigate to={selectAppUrl((x) => x.demand.index).url} />,
    [UserRoleEnum.ProductManager]: <Navigate to={selectAppUrl((x) => x.queueTasks.index).url} />,
  }),
  makeAppLayoutRoute(PublicAuthUserLayout, [
    makeAppRoute((x) => x.user.account.logout, <UserAuthLogoutPage />),
    makeAppRoute((x) => x.user.account.login, <UserAuthLoginPage />),
    makeAppRoute((x) => x.user.account.registration, <UserRegistrationPage />),
  ]),
  makeAppRoute(
    () => ({ url: "*", part: "*", urlType: AppUrlTypeEnum.Private, permission: [] }),
    <ErrorAlert title="Страница не найдена" error="Проверьте правильность введенного адреса" />
  ),
];

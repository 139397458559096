import { format as formatFns, formatDistance, formatDistanceStrict, isMatch, parse } from "date-fns";
import { ru } from "date-fns/locale";

const formats = {
  /**
   * @example 11.02.2022
   */
  dateOnly: "dd.MM.yyyy",
  /**
   * @example 1 марта 2022
   */
  dayFullMonthYear: "d MMMM yyyy",
  /**
   * @example 2022.03.02
   */
  yearMonthDay: "yyyy.MM.dd",
  /**
   * @example 1 мар
   */
  dayAndShortMonth: "d MMM",
};

type DateFormats = keyof typeof formats;
type DateFormatter = (date: Date) => string;

export const specialFormatters: Record<string, DateFormatter> = {
  /**
   * Возвращает формат `День ПолныйМесяц [Год, если не совпадает с текущим], Время`
   * @param date Дата
   * @example 24 ноября, 12:24
   * @example 5 апреля 2021, 11:45
   */
  dayFullMonthYearIfDifferentAndTime: (date: Date) =>
    `d MMMM${date.getFullYear() !== new Date().getFullYear() ? " yyyy" : ""}, HH:mm`,
};

/**
 * Возвращает разницу во времени между переданной датой и текущей датой
 * в читаемом виде
 * @param dateFrom Дата от которой идет отчет периода
 * @example 11 месяцев назад, около 1 года назад, 1 день назад
 */
const getDistanceToNow = (dateFrom: Date): string =>
  formatDistance(dateFrom, new Date(), {
    addSuffix: true,
    locale: ru,
    includeSeconds: true,
  });

const getDistanceToNowStrict = (dateFrom: Date): string =>
  formatDistanceStrict(dateFrom, new Date(), {
    addSuffix: true,
    locale: ru,
  });

/**
 * Возвращает объект даты из строки формата dd.MM.yyyy
 * @param date Строка в формате dd.MM.yyyy
 * @example "10.12.2021" -> Date
 */
const parseDateFromString = (date: string): Date => {
  return parse(date, formats.dateOnly, new Date(), { locale: ru });
};

/**
 * Возвращает признак соответствия строки заданному формату
 * @param date Дата
 * @param formatType Формат даты
 * @example ("10.12.2021", "dateOnly") -> true
 * @example ("10-12-2021", "dateOnly") -> false
 */
const isDateMatch = (date: string, formatType: DateFormats): boolean => {
  return isMatch(date, formats[formatType], { locale: ru });
};

/**
 * Возвращает дату в заданном формате
 * @param date Дата
 * @param formatType Формат даты или функция, возвращающая формат даты
 * @see specialFormatters
 */
const format = (date: Date, formatType: DateFormats | DateFormatter): string => {
  const dateFormat = typeof formatType === "string" ? formats[formatType as DateFormats] : formatType(date);

  return formatFns(date, dateFormat, { locale: ru });
};

export const dateTimeHelper = {
  format,
  getDistanceToNow,
  getDistanceToNowStrict,
  parseDateFromString,
  isDateMatch,
};

import { appApi } from "common/api";
import { ApplicationUpdaterGetVersionResponse } from "application/updater/api/dto/response/ApplicationUpdaterGetVersionResponse";

export const applicationUpdaterApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    applicationUpdaterGetVersion: build.query<ApplicationUpdaterGetVersionResponse, unknown>({
      queryFn: async (arg, api) => {
        try {
          const response = await fetch("/version.json", { method: "get", cache: "no-cache", signal: api.signal });
          const data = (await response.json()) as ApplicationUpdaterGetVersionResponse;

          return { data };
        } catch {
          return { error: "error" };
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useLazyApplicationUpdaterGetVersionQuery } = applicationUpdaterApi;

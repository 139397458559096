import React, { useEffect } from "react";
import { useInfiniteScroll } from "../hooks/useInfiniteScroll";

export interface InfiniteScrollProps {
  initialPage?: number;
  hasMore: boolean;
  loading: boolean;
  endMessage?: React.ReactNode;
  loader?: React.ReactNode;
  children?: React.ReactNode;
  onPageChanged?: () => void | Promise<void>;
  className?: string;
}

export const InfiniteScroll = ({
  hasMore,
  loading,
  initialPage,
  loader,
  endMessage,
  children,
  onPageChanged,
  className,
}: InfiniteScrollProps) => {
  const [page, observerRef] = useInfiniteScroll(hasMore, loading, { initialPage });

  useEffect(() => {
    if (page !== initialPage) {
      onPageChanged?.();
    }
  }, [initialPage, onPageChanged, page]);

  return (
    <div className={className}>
      {children}
      {hasMore && !loading && <div ref={observerRef} />}
      {loading && loader}
      {!hasMore && !loading && endMessage}
    </div>
  );
};

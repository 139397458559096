import { boolean, InferType, object, ref, string } from "yup";

export const userRegistrationAddPasswordFormValidationSchema = object({
  password: string().default("").min(6, "Пароль должен содержать минимум 6 символов").required("Укажите пароль"),
  showPassword: boolean().default(false),
  repeatPassword: string()
    .default("")
    .when("showPassword", {
      is: false,
      then: string()
        .default("")
        .oneOf([ref("password")], "Пароли должны совпадать")
        .required("Повторите пароль"),
    }),
});

export type UserRegistrationAddPasswordFormValues = InferType<typeof userRegistrationAddPasswordFormValidationSchema>;

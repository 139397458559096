import React from "react";
// eslint-disable-next-line import/no-unresolved
import { RouteObject } from "react-router/lib/router";
import { AppLayoutRoute, AppRoute } from "common/routes/types/AppRoute";
import { Outlet } from "react-router-dom";
import { AppRouteElement } from "common/routes/components/AppRouteElement";
import { AnyAppUrl } from "common/urls/types/AppUrl";
import { AppRoutePermissionElement } from "common/routes/components/AppRoutePermissionElement";

export const mapAppRoutes = (routes: (AppLayoutRoute | AppRoute)[]): RouteObject[] => {
  return routes.map((x): RouteObject => {
    const layout = x as AppLayoutRoute;
    if (layout.isLayout) {
      return {
        element: (
          <layout.component>
            <Outlet />
          </layout.component>
        ),
        children: layout.nested ? mapAppRoutes(layout.nested) : undefined,
      };
    }

    const appRoute = x as AppRoute;
    return {
      element: (
        <AppRouteElement url={appRoute.url}>
          {appRoute.permissionElement ? (
            <AppRoutePermissionElement baseElement={appRoute.element} permissionElement={appRoute.permissionElement} />
          ) : (
            appRoute.element
          )}
        </AppRouteElement>
      ),
      path: appRoute.url.url,
      index: (appRoute.url as AnyAppUrl).part === undefined,
      children: appRoute.nested ? mapAppRoutes(appRoute.nested) : undefined,
    };
  });
};

import React from "react";
import { Button, CircularProgress } from "@mui/material";

export interface QueueTasksDetailsSendDecisionButtonProps {
  decision: string;
  title: string;
  onClick: (decision: string) => void | Promise<void>;
  disabled?: boolean;
  loading?: boolean;
}

export const QueueTasksDetailsSendDecisionButton = ({
  decision,
  title,
  onClick,
  disabled,
  loading,
}: QueueTasksDetailsSendDecisionButtonProps) => {
  const onButtonClick = () => onClick(decision);

  return (
    <Button type="button" onClick={onButtonClick} variant="contained" color="primary" fullWidth disabled={disabled}>
      {title} {loading && <CircularProgress size={20} sx={{ marginLeft: 2 }} color="inherit" />}
    </Button>
  );
};

import React, { useState } from "react";
import { Box, Button, Grid, LinearProgress, Link } from "@mui/material";
import { useFormik } from "formik";
import { loginFormValidationSchema, LoginFormValues } from "user/auth/types/LoginFormValues";
import { useUserAuthLogin } from "user/auth/hooks/useUserAuthLogin";
import { PasswordTextField, PhoneTextField } from "common/ui/inputs";
import { Link as RouterLink } from "react-router-dom";
import { selectAppUrl } from "common/urls";

export const LoginForm = () => {
  const [login, options] = useUserAuthLogin();

  const form = useFormik<LoginFormValues>({
    initialValues: loginFormValidationSchema.getDefault(),
    validationSchema: loginFormValidationSchema,
    onSubmit: async (values, helpers) => {
      const response = await login(loginFormValidationSchema.cast(values));
      if (response?.data?.message) {
        helpers.setFieldError("phoneNumber", response?.data?.message);
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box
      component="form"
      onSubmit={form.handleSubmit}
      noValidate
      sx={(theme) => ({
        mt: 1,
        display: "inline-flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "100%",
      })}
    >
      <PhoneTextField
        label="Номер телефона"
        name="phoneNumber"
        value={form.values.phoneNumber}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
        helperText={form.touched.phoneNumber && form.errors.phoneNumber}
        disabled={options.isLoading}
        required
      />

      <PasswordTextField
        error={form.touched.password && Boolean(form.errors.password)}
        disabled={options.isLoading}
        required
        showPassword={showPassword}
        onShowPasswordClick={handleClickShowPassword}
        value={form.values.password}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        helperText={form.touched.password ? form.errors.password : undefined}
        autoComplete="current-password"
        label="Пароль"
        name="password"
      />
      {form.isSubmitting && <LinearProgress color="inherit" />}

      <Button type="submit" fullWidth variant="contained" color="error" disabled={options.isLoading}>
        Войти
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/" variant="body2">
            Забыли пароль?
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={selectAppUrl((x) => x.user.account.registration).url} variant="body2">
            Нет аккаунта? Зарегистрироваться
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

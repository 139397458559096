import React from "react";
import { FileCard, getDownloadFileLink } from "files/download";
import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { dateTimeHelper, specialFormatters } from "common/helpers/date";
import { CommentId } from "common/types";
import { useAppSelector } from "common/store";
import { selectCommentsById } from "demand/comments/store/demandComment.selectors";

export interface DemandCommentItemProps {
  commentId: CommentId;
}

export const DemandCommentItem = ({ commentId }: DemandCommentItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const comment = useAppSelector((state) => selectCommentsById(state, commentId))!;

  return (
    <Stack spacing={1} alignItems="start" mt={2} sx={{ border: 1, borderColor: grey[400], borderRadius: 1 }}>
      <Stack spacing={1} sx={{ display: "flex", width: "100%" }}>
        <Box
          p={2}
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: grey[400],
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {comment.author}
          </Typography>
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {dateTimeHelper.format(new Date(comment.dateCreated), specialFormatters.dayFullMonthYearIfDifferentAndTime)}
          </Typography>
        </Box>

        <Typography p={2} color="text.secondary">
          {comment.text}
        </Typography>
      </Stack>
      {comment.files.map((x) => (
        <Box key={x.fileId} width="100%" p={1}>
          <FileCard name={x.fileName} downloadLink={getDownloadFileLink(x)} dateCreated={new Date(x.dateCreated)} />
        </Box>
      ))}
    </Stack>
  );
};

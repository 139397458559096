import React from "react";
import { AppMenuItem } from "common/navigation/menu/types/AppMenuItem";
import { selectAppUrl } from "common/urls";
import BookmarkBorderSharpIcon from "@mui/icons-material/BookmarkBorderSharp";
import TaskIcon from "@mui/icons-material/Task";
import AddIcon from "@mui/icons-material/Add";

export const appMenu: AppMenuItem[] = [
  {
    title: "Мои задачи",
    url: selectAppUrl((x) => x.queueTasks.index),
    matches: [selectAppUrl((x) => x.queueTasks.index).url],
    icon: <TaskIcon />,
  },
  {
    title: "Мои заявки",
    url: selectAppUrl((x) => x.demand.index),
    matches: [selectAppUrl((x) => x.demand.index).url],
    icon: <BookmarkBorderSharpIcon />,
  },
  {
    title: "Оформить заявку",
    url: selectAppUrl((x) => x.products.leasing.create),
    matches: [selectAppUrl((x) => x.products.leasing.create).url],
    icon: <AddIcon />,
  },
];

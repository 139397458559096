import React, { useEffect } from "react";
import { useUserAuthLogout } from "user/auth/hooks/useUserAuthLogout";

export const UserAuthLogoutPage = () => {
  const [logout] = useUserAuthLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return null!;
};

import React from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useAppPagination } from "common/ui/lists/infinite-scroll/hooks/useAppPagination";
import { RootState, useAppSelector } from "common/store";
import { Button, CircularProgress, LinearProgress, Stack, Typography } from "@mui/material";
import { InfiniteScroll } from "common/ui/lists/infinite-scroll/components/InfiniteScroll";
import { Pagination } from "common/filters";

const InformationText = ({ children }: { children: React.ReactNode }) => (
  <Typography textAlign="center" component="div" variant="caption" mt={2} mb={2}>
    {children}
  </Typography>
);

export interface AppInfinityScrollTitles {
  end: string;
  hasNoLoadedItems: string;
  error?: string;
  navigateToFirstPage?: string;
  nextPageLoading?: string;
}

export interface AppInfinityScrollProps {
  page: number;
  paginatedStateSelector: (state: RootState) => Pagination | undefined;
  idsSelector: (state: RootState) => EntityId[];
  titles: AppInfinityScrollTitles;
  hasPreviousPage?: boolean;
  children: React.ReactNode;
  onPreviousPageButtonClick?: () => void | Promise<void>;
  onPageChanged: () => void | Promise<void>;
  onErrorReloadButtonClick: () => void | Promise<void>;
  isLoading: boolean;
  isFailed: boolean;
  isSucceeded: boolean;
}

export const AppInfinityScroll = ({
  page,
  hasPreviousPage,
  children,
  paginatedStateSelector,
  idsSelector,
  titles,
  onPreviousPageButtonClick,
  onPageChanged,
  onErrorReloadButtonClick,
  isFailed,
  isLoading,
  isSucceeded,
}: AppInfinityScrollProps) => {
  const pagination = useAppSelector(paginatedStateSelector);
  const ids = useAppSelector(idsSelector);

  const { isEmptyLoading, isEmptyList, hasNoLoadedItems, hasMore } = useAppPagination(
    isLoading,
    isFailed,
    isSucceeded,
    ids,
    pagination
  );

  return (
    <Stack spacing={1} justifyContent="center">
      {isEmptyLoading && <LinearProgress />}
      {hasPreviousPage === true && (
        <Button type="button" variant="contained" size="small" onClick={onPreviousPageButtonClick}>
          {titles.navigateToFirstPage ?? "Перейти к началу"}
        </Button>
      )}
      {!isEmptyList && (
        <InfiniteScroll
          hasMore={hasMore}
          loading={isLoading}
          initialPage={page}
          loader={
            <InformationText>
              {titles.nextPageLoading ?? "Загружаем следующую страницу"}
              <CircularProgress color="inherit" size={10} sx={{ marginLeft: 1 }} />
            </InformationText>
          }
          endMessage={!isEmptyList && !isFailed && page > 1 && <InformationText>{titles.end}</InformationText>}
          onPageChanged={onPageChanged}
        >
          {children}
        </InfiniteScroll>
      )}
      {hasNoLoadedItems && <InformationText>{titles.hasNoLoadedItems}</InformationText>}
      {isFailed && (
        <InformationText>
          {titles.error ?? "Ошибка при загрузке данных"}
          <div>
            <Button size="small" variant="text" type="button" onClick={onErrorReloadButtonClick}>
              Повторить
            </Button>
          </div>
        </InformationText>
      )}
    </Stack>
  );
};

import { useEffect, useState } from "react";

export const useHasPreviousPage = <TFilteringObject extends { pageNumber: number | undefined }>(
  filteringObject: TFilteringObject
) => {
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (hasPreviousPage === undefined) {
      setHasPreviousPage(filteringObject.pageNumber !== undefined && filteringObject.pageNumber > 1);
    }

    if (filteringObject.pageNumber === 1 && hasPreviousPage) {
      setHasPreviousPage(false);
    }
  }, [filteringObject.pageNumber, hasPreviousPage]);

  return hasPreviousPage;
};

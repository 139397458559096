import React from "react";
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigationStateFromWithFallback } from "common/navigation/navigation-state";
import { Link } from "react-router-dom";

export interface BackStateLinkButtonProps {
  fallbackLink: string;
  fallbackTitle: string;
}

export const BackStateLinkButton = ({ fallbackLink, fallbackTitle }: BackStateLinkButtonProps) => {
  const { from } = useNavigationStateFromWithFallback(fallbackLink, fallbackTitle);
  return (
    <Button component={Link} to={from.url} startIcon={<ChevronLeftIcon />} variant="outlined" color="inherit">
      {from.backTitle}
    </Button>
  );
};

import React, { useMemo } from "react";
import { getUserMenu } from "common/navigation/menu/utils/getUserMenu";
import { AppMenuItem } from "common/navigation/menu/components/AppMenuItem";
import { useAppSelector } from "common/store";
import { selectUserAuth } from "user/auth";

export const AppMenu = () => {
  const { user } = useAppSelector(selectUserAuth);
  const menuItems = useMemo(() => (user?.roles.values ? getUserMenu(user.roles.values) : []), [user?.roles.values]);

  return (
    <>
      {menuItems.map((x) => (
        <AppMenuItem item={x} key={x.title} />
      ))}
    </>
  );
};

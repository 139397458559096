import { styled } from "@mui/material";
import { ReactComponent as Logo } from "./assets/logo_small.svg";
import { ReactComponent as DarkLogo } from "./assets/logo-dark.svg";

export const BrandDarkLogo = styled(DarkLogo)`
  height: 70px;
  width: 300px;
`;

export const BrandLogo = Logo;

import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import React from "react";
import { BaseFormTextFieldProps } from "common/ui/types/BaseFormTextFieldProps";

export type PhoneTextFieldProps = BaseFormTextFieldProps;

export const PhoneTextField = ({
  value,
  label,
  helperText,
  onChange,
  disabled,
  name,
  onBlur,
  required,
  error,
  autoFocus,
}: PhoneTextFieldProps) => {
  return (
    <NumberFormat
      format="+7 (###) ### ## ##"
      mask="_"
      customInput={TextField}
      margin="normal"
      required={required}
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      autoComplete="tel"
      autoFocus={autoFocus}
      type="tel"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      disabled={disabled}
      inputProps={{ inputMode: "tel" }}
      allowEmptyFormatting
    />
  );
};

import React from "react";
import { Stack } from "@mui/material";
import { QueueTasksListHeader } from "queue-tasks/list/components/QueueTasksListHeader";
import { QueueTasksListItemsContainer } from "queue-tasks/list/components/QueueTasksListItemsContainer";
import { useGetQueryParams } from "common/filters";
import { useQueueTasksListFilterMutator } from "queue-tasks/list/hooks/useQueueTasksListFilterMutator";
import { queueTasksListFiltersValidationSchema } from "queue-tasks/list/types/QueueTasksListFilterParams";

export const QueueTasksListPage = () => {
  const [filteringObject] = useGetQueryParams(queueTasksListFiltersValidationSchema);
  const mutator = useQueueTasksListFilterMutator();

  return (
    <Stack spacing={2}>
      <QueueTasksListHeader />
      {filteringObject?.pageNumber >= 1 && (
        <QueueTasksListItemsContainer filteringObject={filteringObject} mutator={mutator} />
      )}
    </Stack>
  );
};

import React from "react";
import { UserId } from "common/types";
import { useFormik } from "formik";
import {
  userRegistrationAddUserFormValidationSchema,
  userRegistrationAddUserFormValueKeys,
  UserRegistrationAddUserFormValues,
} from "user/registration/types/UserRegistrationAddUserFormValues";
import { Alert, Button, LinearProgress, Stack, TextField } from "@mui/material";
import { PhoneTextField } from "common/ui/inputs";
import { formFieldsApiValidationService } from "common/services/form";
import { QueryError } from "common/api/types/QueryError";
import { usePostUserRegistrationAddUserMutation } from "user/registration/api/userRegistrationApi";
import { UserRegistrationAddUserResponse } from "user/registration/api/dto/response/UserRegistrationAddUserResponse";

export interface UserRegistrationAddUserFormProps {
  onSuccess: (userId: UserId, phoneNumber: string) => void | Promise<void>;
}

export const UserRegistrationAddUserForm = ({ onSuccess }: UserRegistrationAddUserFormProps) => {
  const [addUser, options] = usePostUserRegistrationAddUserMutation();

  const form = useFormik<UserRegistrationAddUserFormValues>({
    initialValues: userRegistrationAddUserFormValidationSchema.getDefault(),
    validationSchema: userRegistrationAddUserFormValidationSchema,
    onSubmit: async (values, helpers) => {
      const model = userRegistrationAddUserFormValidationSchema.cast(values);

      const response = await addUser({
        ...model,
        contactEmail: model.contactEmail !== null && model.contactEmail.trim().length === 0 ? null : model.contactEmail,
      });

      const { error } = response as { error: QueryError };
      if (error) {
        formFieldsApiValidationService.setFieldErrors(
          error.data,
          userRegistrationAddUserFormValueKeys,
          helpers.setFieldError
        );

        return;
      }

      const { data } = response as { data: UserRegistrationAddUserResponse };
      if (data) {
        onSuccess(data.userId, model.contactPhoneNumber);
      }
    },
  });

  return (
    <Stack component="form" onSubmit={form.handleSubmit} noValidate spacing={2}>
      <TextField
        margin="normal"
        required
        fullWidth
        variant="outlined"
        label="Фамилия, имя и отчество"
        name="userName"
        autoComplete="name"
        value={form.values.userName}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.userName && Boolean(form.errors.userName)}
        helperText={form.touched.userName && form.errors.userName}
        disabled={form.isSubmitting}
        autoFocus
      />
      <PhoneTextField
        label="Мобильный телефон"
        name="contactPhoneNumber"
        value={form.values.contactPhoneNumber}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.contactPhoneNumber && Boolean(form.errors.contactPhoneNumber)}
        helperText={form.touched.contactPhoneNumber && form.errors.contactPhoneNumber}
        disabled={form.isSubmitting}
        required
      />
      <TextField
        margin="normal"
        fullWidth
        variant="outlined"
        label="Электронная почта"
        name="contactEmail"
        autoComplete="email"
        type="email"
        value={form.values.contactEmail}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.contactEmail && Boolean(form.errors.contactEmail)}
        helperText={form.touched.contactEmail && form.errors.contactEmail}
        disabled={form.isSubmitting}
      />

      {options.isError && options.error && (
        <Alert severity="error">
          {(options.error as QueryError).data?.message ?? "Не удалось установить соединение с сервером"}
        </Alert>
      )}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        disabled={!form.isValid || !form.dirty || form.isSubmitting}
      >
        Подтвердить телефон
      </Button>
      {form.isSubmitting && <LinearProgress color="inherit" />}
    </Stack>
  );
};

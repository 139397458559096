/* eslint-disable @typescript-eslint/no-empty-interface */
import {createTheme} from "@mui/material";

export interface AppTheme {
  customColors: {
    dark: string;
  };
  layout: {
    asideWidth: number;
  };
}

declare module "@mui/material/styles" {
  interface Theme extends AppTheme {}
  interface ThemeOptions extends Partial<AppTheme> {}
}

export const appTheme = createTheme({ layout: { asideWidth: 270 }, customColors: { dark: "#1c1b1b" } });

import {AnySchema, InferType} from "yup";
import {NotUndefinedKeys} from "common/types";
import {getObjectNotUndefinedKeys} from "common/utils/getObjectNotUndefinedKeys";

/**
 * Возвращает коллекцию ключей объекта, значения которых по умолчанию указаны в схеме
 * @param schema Схема валидации
 */
export const getSchemaDefaultKeys = <TSchema extends AnySchema>(
  schema: TSchema
): NotUndefinedKeys<InferType<TSchema>>[] => {
  return getObjectNotUndefinedKeys<InferType<TSchema>>(schema.getDefault());
};

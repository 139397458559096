import React from "react";
import { ProductLeasingCreateForm } from "product-leasing/create/components/ProductLeasingCreateForm";
import { usePageTitle } from "common/navigation/page-title";

export const ProductLeasingCreatePage = () => {
  usePageTitle("Новая заявка");

  return (
    <div>
      <ProductLeasingCreateForm />
    </div>
  );
};

import React from "react";
import { AppMenuItem as AppMenuItemType } from "common/navigation/menu/types/AppMenuItem";
import { ListItemButton } from "@mui/material";
import { Link, matchPath, useLocation } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export interface AppMenuItemProps {
  item: AppMenuItemType;
}

export const AppMenuItem = ({ item }: AppMenuItemProps) => {
  const location = useLocation();
  const match = item.matches?.filter((path) => matchPath(path, location.pathname)).length;

  return (
    <ListItemButton
      component={Link}
      to={item.url.url}
      sx={(theme) => ({
        backgroundColor: match ? theme.palette.action.selected : theme.palette.grey[100],
      })}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItemButton>
  );
};

import { Segment } from "common/urls/types/Segment";
import { AppUrlTypeEnum } from "common/urls/types/AppUrlTypeEnum";
import { UserRoleEnum } from "user/common/types";
import { AppUrl } from "common/urls/types/AppUrl";

export const makeAppUrl = <TUrl extends string, TUrlPart extends Segment<TUrl>>(
  url: TUrl,
  part: TUrlPart,
  urlType: AppUrlTypeEnum,
  permission: UserRoleEnum[] = []
): AppUrl<TUrl, TUrlPart> => ({
  url,
  part,
  urlType,
  permission,
});
